
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { branchApiNm } from 'branch/constant/branchApiNm';
import { driverTripPaymentColumns, driverTripPaymentEndColumns } from './columns';
import { green, red } from '@mui/material/colors';
import ComboBox from 'components/ComboBox';
import { alertError } from 'components/Alert';
import SelectMonthDialog from './SelectMonthDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { numberFormat } from 'utils';
import DailyJobDialogV3 from 'pages/Menu1/DailyJob/DailyJobDialogV3';
import dayjs from 'dayjs';
import { ArticleRounded, CloudDownloadRounded } from '@mui/icons-material';
import AddDriverTripDialog from './AddDriverTripDialog';
import OtherDriverTripDialog from './OtherDriverTripDialog';
import { expensePOIApiNm } from 'pages/Menu4/ExpensePOI/constant';
import { printSummaryByCus } from './printSummaryByCus';
import { jobOrderApiNm, jobOrderColNm } from 'constants/jobOrderConstant';
import CheckboxFormControl from 'components/CheckboxFormControl';
import { shipmentColNm } from 'constants/shipmentConstant';
import FileDropOne from 'components/FileUplaod/FileDropOne';
import Tesseract from 'tesseract.js';

// let selectedId = null;
let lastFilter = null
let jobOrdId = null
let selectedIds = []
let selectedSalDte = null
let selectedPCItmId = null
let selectedRow = {}
let suggestDrvIds = []


const watchCols = [87, 88]
// const watchFields = watchCols.map(id => `E${id}`)

const DriverTripPayment = () => {
  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogSelectMonth, setDialogSelectMonthOpen] = useState(false)
  const [dialogAddDriverTripOpen, setDialogAddDriverTripOpen] = useState(false)
  const [dialogOtherDriverTripOpen, setDialogOtherDriverTripOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [selectionModel, setSelectionModel] = useState([])
  const [ref3, setRef3] = useState("")
  const [fileData, setFileData] = useState(null)

  const textFieldProps = useCallback((name) => ({
    size: "small",
    sx: { width: 120 },
    value: filterData[name] || "",
    onChange: (e) => { setFilterData(o => ({ ...o, [name]: e.target.value || null })) }
  }), [filterData])

  const monthPickerProp = useMemo(() => ({
    disableMaskedInput: true,
    openTo: "month",
    views: ["year", "month"],
    label: "คิดเงินเดือน",
    inputFormat: "MMMM YYYY",
    value: filterData.SalDte,
    onChange: (newValue) => { setFilterData(o => ({ ...o, SalDte: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} sx={{ width: 160 }} />,

  }), [filterData.SalDte])

  const setAllData = useCallback((datas) => {
    const newData = []
    let no = 0;
    for (const data of datas) {
      no++;
      const result = {
        id: data.id,
        No: no,
        ...data
      }
      let othAmnt = 0;
      let othDscp = new Set()
      let expTotal = 0;
      if (data.Exps) {
        for (const exp of data.Exps) {
          expTotal += +exp.Amnt
          if (watchCols.includes(exp.ExpId)) {
            result[`E${exp.ExpId}`] = +exp.Amnt
          } else {
            othAmnt += +exp.Amnt
            othDscp.add(exp.ExpNm)
          }
        }
        if (othAmnt) {
          // console.log("othDscp::", othDscp)  
          result.OthAmnt = othAmnt
          result.OthDscp = [...othDscp].join(', ')
        }
      }
      result.ExpTotal = expTotal
      newData.push(result)
    }
    setDataTable(newData)
  }, [])

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getDriverTripPayment, filter).then(value => {
      if (value.data) {
        // setDataTable(addIdForDataGrid(value.data, 'PCTrnsId'))
        lastFilter = filter
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])



  const handleCellClick = useCallback((params) => {
    // selectedId = params.id
    if (params.field === 'ShowDetail') {
      jobOrdId = params.row.JobOrdId
      setDialogOpen(true)
      // } else if (params.field === 'Print') {
      //   ax.post(branchApiNm.getPrintData, { PCTrnsId: selectedId }).then(value => {
      //     if (value.data && value.data) {
      //       printJobOrderExpense({ joData: value.data, msData })
      //     }
      //   })
    } else if (params.field === "AddData") {
      selectedPCItmId = null
      selectedRow = params.row
      suggestDrvIds = [params.row.Sts1DrvId, params.row.Sts3DrvId, params.row.Sts5DrvId].filter(id => id)
      console.log("suggestDrvIds::", suggestDrvIds)
      setDialogAddDriverTripOpen(true)
    } else if (params.field === "OthAmnt") {
      if (params.value) {
        selectedRow = params.row
        setDialogOtherDriverTripOpen(true)
      }
    } else if (params.field === 'SalDte') {
      selectedSalDte = params.value
      if (selectionModel.includes(params.id)) {

        let allOK = true
        for (const id of selectionModel) {
          if (id.split("-")[1] === "X") {
            allOK = false
            break
          }
        }

        if (!allOK) {
          alertError("มีบางข้อมูลยังไม่ลงค่าเที่ยว ไม่สามารถลงคิดเงินเดือนได้")
          return
        }

        selectedIds = dataTable.filter(r => selectionModel.includes(r.id)).map(r => r.Exps.map(exp => exp.PCTrnsId)).flat()
        console.log("selectedIds::", selectedIds)
      } else {
        const foundRow = dataTable.find(r => r.id === params.id)
        if (foundRow.id.split("-")[1] === "X") {
          alertError("มีบางข้อมูลยังไม่ลงค่าเที่ยว ไม่สามารถลงคิดเงินเดือนได้")
          return
        }
        selectedIds = foundRow.Exps.map(exp => exp.PCTrnsId)
      }



      setDialogSelectMonthOpen(true)


    }
  }, [selectionModel, dataTable])

  // const handleRowDoubleClick = useCallback((params) => {
  //   selectedId = params.id
  //   setDialogOpen(true)
  // }, [])

  const onFinish = useCallback((data) => {
    getData(lastFilter)
  }, [getData])


  const onSelectMonthDialogFinish = useCallback((data) => {
    const postData = {
      PCTrnsIds: selectedIds,
      SalDte: data,
      getArgs: lastFilter
    }
    ax.post(branchApiNm.insertDeleteSalaryPCTrnsaction, postData).then(value => {
      if (value.data) {
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])

  const handleCellEditCommit = useCallback((params) => {
    const expId = +params.field.substring(1)

    if (params.field === "Ref3") {
      setDataTable(o => {
        const result = [...o]
        const foundIndex = result.findIndex(r => r.id === params.id)
        if (foundIndex >= 0) {
          result[foundIndex].Ref3 = params.value
          const postData = {
            JobOrdIds: [result[foundIndex].JobOrdId],
            ColValues: { Ref3: params.value },
            LogRmk: `แก้ไขเลขที่อ้างอิง`
          }
          ax.post(jobOrderApiNm.updateJobOrderColumn, postData, false)
        }
        return result
      })
    } else {
      if (params.value === "") {
        let pcTrnsIds = []
        if (selectionModel.includes(params.id)) {
          const filtered = dataTable.filter(r => selectionModel.includes(r.id))
          for (const row of filtered) {
            const foundExp = row.Exps.find(exp => exp.ExpId === expId)
            if (foundExp) {
              pcTrnsIds.push(foundExp.PCTrnsId)
            }
          }
        }
        else {
          const row = dataTable.find(r => r.id === params.id)
          const foundExp = row.Exps.find(exp => exp.ExpId === expId)
          if (foundExp) {
            pcTrnsIds.push(foundExp.PCTrnsId)
          }
        }
        ax.post(branchApiNm.deleteDriverPayment, { PCTrnsIds: pcTrnsIds, getArgs: lastFilter }).then(value => {
          if (value.data) {
            setAllData(value.data)
          }
        })
      } else {
        if (selectionModel.includes(params.id)) {
          const updateArgsArr = []
          const insertArgsArr = []

          for (const id of selectionModel) {

            const row = dataTable.find(r => r.id === id)
            const foundExp = row.Exps.find(exp => exp.ExpId === expId)
            if (foundExp) {
              updateArgsArr.push({
                PCTrnsId: foundExp.PCTrnsId,
                PCItmId: foundExp.PCItmId,
                Amnt: params.value,
              })
            } else {
              insertArgsArr.push({
                ExpId: expId,
                DrvId: row.DrvId,
                JobOrdId: row.JobOrdId,
                Amnt: params.value,
                SalDte: row.SalDte ? dayjs(row.SalDte).format("YYYY-MM-DD") : null
              })
            }
          }
          const postData = {
            updateArgsArr,
            insertArgsArr,
            getArgs: lastFilter
          }
          ax.post(branchApiNm.insertUpdateDriverPayments, postData).then(value => {
            if (value.data) {
              setAllData(value.data)
            }
          })
        } else {
          const row = dataTable.find(r => r.id === params.id)
          const foundExp = row.Exps.find(exp => exp.ExpId === expId)
          if (foundExp) {
            const postData = {
              PCTrnsId: foundExp.PCTrnsId,
              PCItmId: foundExp.PCItmId,
              Amnt: params.value,
              getArgs: lastFilter
            }
            ax.post(branchApiNm.updateDriverPaymentAmnt, postData, false).then(value => {
              if (value.data) {
                setAllData(value.data)
              }
            })
          } else {
            const postdata = {
              ExpId: expId,
              DrvId: row.DrvId,
              JobOrdId: row.JobOrdId,
              Amnt: params.value,
              SalDte: row.SalDte ? dayjs(row.SalDte).format("YYYY-MM-DD") : null,
              getArgs: lastFilter
            }
            ax.post(branchApiNm.insertDriverPayment, postdata, false).then(value => {
              if (value.data) {
                setAllData(value.data)
              }
            })
          }
        }
      }
    }

  }, [dataTable, ax, selectionModel, setAllData])

  const handleInsertTripAmnt = useCallback(() => {
    const selectedRows = dataTable.filter(row => selectionModel.includes(row.id))
    const jobIds = [...new Set(selectedRows.map(row => row.JobId))]
    ax.post(expensePOIApiNm.getExpensePOI, { JobIds: jobIds, ExpTypId: 6 }).then(value => {
      if (value.data) {
        if (value.data.length === 0) {
          alertError("ไม่พบค่าเที่ยวในงานที่เลือก")
          return
        }
        const expJobObj = value.data.reduce((acc, row) => {
          if (!acc[row.JobId]) {
            acc[row.JobId] = [{ ...row }]
          } else {
            acc[row.JobId].push({ ...row })
          }
          return acc
        }, {})
        const argsArr = []
        const insertArr = (row, expId) => {
          console.log("insertArr expId::", expId)
          const amnt = row.Exps.find(exp => exp.ExpId === expId)?.Amnt
          console.log("amnt::", amnt)
          let drvId = null
          if (expId === 87) {
            drvId = row.Sts3DrvId
          }

          if (drvId && !amnt) {
            const found = expJobObj[row.JobId].find(exp => exp.ExpId === expId)
            console.log("found::", found)
            if (found) {
              argsArr.push({
                ExpId: expId,
                DrvId: drvId,
                JobOrdId: row.JobOrdId,
                Amnt: +found.UntPr,
                SalDte: row.SalDte ? dayjs(row.SalDte).format("YYYY-MM-DD") : null
              })
            }
          }
        }
        for (const row of selectedRows) {
          if (row.Sts3DrvId) {
            insertArr(row, 87)
          }
        }
        const postData = {
          argsArr: argsArr,
          getArgs: lastFilter
        }
        ax.post(branchApiNm.insertDriverPaymentBulk, postData).then(value => {
          if (value.data) {
            setAllData(value.data)
          }
        })
        console.log("argsArr::", argsArr)
      }
    })
  }, [dataTable, ax, selectionModel, setAllData])

  const driverTripPaymentColumnsMemo = useMemo(() => {
    const newCols = []
    for (const id of watchCols) {
      newCols.push({
        field: `E${id}`,
        headerName: msData.expObj[id]?.ExpNm,
        width: 90,
        align: 'right',
        editable: true,
        valueFormatter: ({ value }) => value === null || value === undefined ? '-' : numberFormat(value)
      })
    }
    return [
      ...driverTripPaymentColumns,
      ...newCols,
      ...driverTripPaymentEndColumns
    ]
  }, [msData.expObj])

  const onAddDriverTripDialogFinish = useCallback((data) => {
    if (data) {
      setAllData(data)
    } else {
      getData(lastFilter)
    }
  }, [setAllData, getData])

  const handleOpenSummaryFinDte = useCallback(() => {
    if (selectionModel.length === 0) {
      alertError("กรุณาเลือกข้อมูล")
      return
    }
    const filteredDataTable = dataTable.filter(row => selectionModel.includes(row.id))

    const firstRow = filteredDataTable.find(row => row.id === selectionModel[0])
    if (!firstRow) return

    const firstCus = firstRow.CusId
    if (!filteredDataTable.every(row => row.CusId === firstCus)) {
      alertError("กรุณาเลือกข้อมูลที่มีลูกค้าเดียวกัน")
      return
    }

    printSummaryByCus({ dataTable: filteredDataTable, msData })
  }, [dataTable, msData, selectionModel])

  const handleRef3Keyup = useCallback((e) => {
    if (e.key === "Enter") {
      if (selectionModel.length !== 1) {
        alertError("กรุณาเลือก 1 รายการ")
        return
      }
      const foundIndex = dataTable.findIndex(row => row.id === selectionModel[0])
      if (foundIndex >= 0) {
        setSelectionModel([dataTable[foundIndex + 1].id])
        setDataTable(o => {
          const newData = [...o]
          newData[foundIndex].Ref3 = ref3

          const postData = {
            JobOrdIds: [newData[foundIndex].JobOrdId],
            ColValues: { Ref3: ref3 },
            LogRmk: `แก้ไขเลขที่อ้างอิง`
          }
          ax.post(jobOrderApiNm.updateJobOrderColumn, postData, ref3)

          return newData
        })
      }
    }
  }, [selectionModel, ref3, dataTable, ax])

  useEffect(() => {
    if (selectionModel.length === 1) {
      const found = dataTable.find(row => row.id === selectionModel[0])
      if (found.CusSNm === "DHL") {
        setRef3(`BKK-${found.JobSNm}-${dayjs(found.AptTm).add(-1, 'day').format("YYYYMMDD")}-`)
      }
    }
  }, [selectionModel, dataTable])

  useEffect(() => {
    if (fileData) {
      const image = URL.createObjectURL(fileData)
      Tesseract.recognize(image, 'eng', { /*logger: (m) => console.log(m),*/ })
        .then(({ data: { text } }) => {
          const lines = text.split("\n")
          let tr = ""
          let pick = ""
          for(const line of lines){
            console.log("line::", line) 
            if(line.startsWith("PICK-") || line.startsWith("PKU-")){
              pick = line.replace("PICK-", "").replace("PKU-", "").trim()
            }
            if(line.startsWith("tr-")){
              tr = line
            }
          }
          setRef3(`${pick},${tr}`)
          // setText(text);
        });
    }
  }, [fileData])
  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        {/* <OCRComponent /> */}
        <Box flex={1} />
        <TextField {...textFieldProps('JobOrdId')} label="เลขที่ใบงาน" />
        <TextField {...textFieldProps('ContNo')} label="หมายเลขตู้" />
        <TextField {...textFieldProps('ShpmTypId')} label={shipmentColNm.ShpmTypId} select
          value={filterData.ShpmTypId ?? ""} onChange={(e) => setFilterData(o => ({ ...o, ShpmTypId: e.target.value }))}>
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          {msData.shipmentTypes.map(shpmTyp => (
            <MenuItem key={shpmTyp.ShpmTypId} value={shpmTyp.ShpmTypId}>{shpmTyp.Name}</MenuItem>
          ))}
        </TextField>
        <ComboBox sx={{ width: 120 }} options={msData.cusCombo} label={jobOrderColNm.CusSNm}
          selectedId={filterData.CusId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, CusId: id }))}
        />
        <ComboBox sx={{ width: 120 }} options={msData.jobCombo} label={jobOrderColNm.JobSNm}
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
        />
        <ComboBox sx={{ width: 120 }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <DateTimeSelect sx={{ width: 150 }} label='วันนัด' start={filterData.AptTmSt} end={filterData.AptTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, AptTmSt: st, AptTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <DateTimeSelect sx={{ width: 150 }} label='วันที่ลงจบ' start={filterData.FinDteSt} end={filterData.FinDteEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, FinDteSt: st, FinDteEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <Box sx={{ position: "relative" }}>
          <DesktopDatePicker {...monthPickerProp} />
          <CheckboxFormControl sx={{ position: "absolute", bottom: -24, left: 0 }} label="ระบุุคิดเงินเดือน"
            checked={filterData.IsFilterSalDte === 1}
            onChange={(e) => setFilterData(o => ({ ...o, IsFilterSalDte: e.target.checked ? 1 : 0 }))} />
        </Box>
        <TextField {...textFieldProps("IsPaySts")} sx={{ width: 170 }} label="สถานะลงค่าเที่ยว" select
          value={filterData.IsPaySts ?? ""} onChange={(e) => setFilterData(o => ({ ...o, IsPaySts: e.target.value ?? null }))}>
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          <MenuItem value={1}>ลงค่าเที่ยวแล้ว</MenuItem>
          <MenuItem value={0}>ยังไม่ลงค่าเที่ยว</MenuItem>
        </TextField>

        <SearchButton onClick={(() => getData(filterData))} />
        <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <BoxFR>
        {/* <Button variant="contained" onClick={handleSetSalDte}>กำหนดเดือนคิดค่าเที่ยว</Button> */}

        {/* <Button variant="contained" onClick={() => {} }>รายการเงินเบิกล่วงหน้า</Button> */}
        <Button variant="contained" onClick={handleInsertTripAmnt} disabled={selectionModel.length === 0}>
          <CloudDownloadRounded sx={{ mr: 1 }} /> ดึงค่าเที่ยวจากที่บันทึกไว้
        </Button>
        <Button variant="contained" onClick={handleOpenSummaryFinDte} disabled={selectionModel.length === 0}>
          <ArticleRounded sx={{ mr: 1 }} />สรุปส่งใบงาน
        </Button>
        <Box flex={1} />
        <FileDropOne fileData={fileData} setFileData={setFileData} />
        <TextField {...textFieldProps('Ref3')} label="เลขที่อ้างอิง" value={ref3} onChange={e => setRef3(e.target.value)}
          onKeyUp={handleRef3Keyup} sx={{ width: 270 }} />
      </BoxFR>
      <Box height='100%' sx={{
        "& .bg-red": { bgcolor: red[100] },
        "& .bg-green": { bgcolor: green[100] }
      }}>
        <DataGridCellExpand
          hideFooter
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
          rows={dataTable}
          columns={driverTripPaymentColumnsMemo}
          onCellEditCommit={handleCellEditCommit}
          onCellClick={handleCellClick}
        // initialState={{
        //   pinnedColumns: {
        //     left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'ShowDetail', "IsCmpt", "Print", "No", "AccNm", "AptTm", "JobOrdId", "Loc", "Amnt", "AddAmnt", "TotalOut", "ExpTotal", "Total", "OverAmnt"],
        //   },
        // }}
        />
      </Box>
      <DailyJobDialogV3
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        selectedId={jobOrdId}
        onFinish={onFinish} />
      <SelectMonthDialog
        oldData={selectedSalDte}
        dialogOpen={dialogSelectMonth}
        setDialogOpen={setDialogSelectMonthOpen}
        onFinish={onSelectMonthDialogFinish} />
      <AddDriverTripDialog
        dialogOpen={dialogAddDriverTripOpen}
        setDialogOpen={setDialogAddDriverTripOpen}
        pcItmId={selectedPCItmId}
        dataRow={selectedRow}
        suggestDrvIds={suggestDrvIds}
        onFinish={onAddDriverTripDialogFinish}
        lastFilter={lastFilter}
      />
      <OtherDriverTripDialog
        jobOrdId={selectedRow?.JobOrdId}
        dialogOpen={dialogOtherDriverTripOpen}
        setDialogOpen={setDialogOtherDriverTripOpen}
        lastFilter={lastFilter}
        suggestDrvIds={suggestDrvIds}
        onFinish={onAddDriverTripDialogFinish}
        joData={selectedRow}
      />
    </BoxFC>
  );
}

export default DriverTripPayment