import { Box, Dialog, DialogContent, Typography } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import dayjs from "dayjs"
import React, { useContext, useEffect, useState } from "react"
import { summaryTodayColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { green, orange } from "@mui/material/colors"

const SummaryToday = ({ dialogOpen, setDialogOpen, driverTable, curDate }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  
  useEffect(() => {
    if (dialogOpen) {
      console.log('driverTable', driverTable)
      const result = []

      const addDataToRow = (row, jo) =>{
        
        const aptTm = dayjs(jo.AptTm)
        const ah1Tm = dayjs(dayjs(jo.AptTm).format("YYYY-MM-DD 12:00"))
        const ah2Tm = dayjs(dayjs(jo.AptTm).format("YYYY-MM-DD 18:00"))
        if(jo.ShpmTypId === 3){
          if(row.SHLoc !== "" && jo.JobSNm) row.SHLoc += ", "
          if(row.SHTm !== "" && jo.JobSNm) row.SHTm += ", "
          row.SHLoc += jo.JobSNm || "-"
          row.SHTm += aptTm.format('HH:mm')
        } else {
          if(aptTm.isBefore(ah1Tm)){
            if(row.AH0Loc !== "" && jo.JobSNm) row.AH0Loc += ", "
            if(row.AH0Tm !== "" && jo.JobSNm) row.AH0Tm += ", "
            row.AH0Loc += jo.JobSNm || "-"
            row.AH0Tm += aptTm.format('HH:mm')
          } else if(aptTm.isBefore(ah2Tm)){
            if(row.AH1Loc !== "" && jo.JobSNm) row.AH1Loc += ", "
            if(row.AH1Tm !== "" && jo.JobSNm) row.AH1Tm += ", "
            row.AH1Loc += jo.JobSNm || "-"
            row.AH1Tm += aptTm.format('HH:mm')
          } else {
            if(row.AH2Loc !== "" && jo.JobSNm) row.AH2Loc += ", "
            if(row.AH2Tm !== "" && jo.JobSNm) row.AH2Tm += ", "
            row.AH2Loc += jo.JobSNm || "-"
            row.AH2Tm += aptTm.format('HH:mm')
          }
        }
        if(row.Rmk !== "" && jo.Rmk) row.Rmk += ", "
        row.Rmk +=  jo.Rmk || ""
      }
      for(const drv of driverTable){
        if(drv.td.length === 0) continue

        for(const jo of drv.td){
          const found = result.find(row => row.DrvId === jo.DrvId && row.TukId === jo.TukId)
          if(!found){
            const newRow = {
              id: result.length + 1,
              No: result.length + 1,
              DrvId: jo.DrvId,
              TukId: jo.TukId,
              DrvNNm: jo.DrvNNm,
              Tel: msData.driverObj[jo.DrvId]?.Tel || "-",
              TukCd: jo.TukCd,
              SHLoc: "",
              SHTm: "",
              AH0Loc: "",
              AH0Tm: "",
              AH1Loc: "",
              AH1Tm: "",
              AH2Loc: "",
              AH2Tm: "",
              Rmk: "",
            }
            addDataToRow(newRow, jo)
            result.push(newRow)
          } else {
            addDataToRow(found, jo)
          }
        }
      }
      setDataTable(result)
    } else {
      setDataTable([])
    }
  }, [dialogOpen, ax, driverTable, msData.driverObj])
  
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายงานสรุปงานประจำวัน"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <Typography variant="h6" align="center">รายงานสรุปงาน ประจำวันที่: {curDate.format("DD/MM/YYYY")}</Typography>
          <Box height={600} sx={{
            "& .shuttle": {bgcolor: green[200]},
            "& .adhoc0": {bgcolor: orange[200]},
            "& .adhoc1": {bgcolor: orange[300]},
            "& .adhoc2": {bgcolor: orange[400]},
          }}>
            <DataGridCellExpand
              hideFooter
              rows={dataTable}
              columns={summaryTodayColumns}
            />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(SummaryToday)