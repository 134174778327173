import { ArticleRounded, CloudDownload } from "@mui/icons-material";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { branchApiNm } from "branch/constant/branchApiNm";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import DateTimeSelect from "components/DateTimeSelect";
import ClearButton from "components/buttons/ClearButton";
import SearchButton from "components/buttons/SearchButton";
import { GlobalStateContext } from "contexts/GlobalStateContext";
// import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { addIdForDataGrid } from "utils";
import { jobOrderApiNm, jobOrderColNm } from "constants/jobOrderConstant";
import { addExpenseJobOrderMTBColumns } from "./columns";
import { expenseJobOrderApiNm } from "constants/expenseJobOrderConstant";
import { alertError } from "components/Alert";
import { expenseJobApiNm } from "constants/expenseJobConstant";
import { shipmentColNm } from "constants/shipmentConstant";
import DHLTableDialog from "./DHLTableDialog";
import DailyJobDialogV3 from "pages/Menu1/DailyJob/DailyJobDialogV3";

const clearFilterData = {
  InvDteSt: null,
  InvDteEn: null,
  AptTmSt: null,
  AptTmEn: null,
  FinDteSt: null,
  FinDteEn: null,
  CusId: null,
  JobId: null,
  E1Sts: null,
  ShpmTypId: null,
}

const initFilterData = {
  ...clearFilterData,
  E1Sts: 0,
  // AptTmSt: dayjs().startOf("day").format("YYYY-MM-DD"),
  // AptTmEn: dayjs().endOf("day").format("YYYY-MM-DD"),
}

let lastFilter = {}
let selectedJobOrdId = null
let curDataTable = []

const AddExpenseJobOrderMTB = () => {

  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [dataTable, setDataTable] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [dialogDHLOpen, setDialogDHLOpen] = useState(false)
  const [dialogJobOrderOpen, setDialogJobOrderOpen] = useState(false)

  const { ax, msData } = useContext(GlobalStateContext)

  const textFieldProps = useCallback((name) => ({
    size: "small",
    label: jobOrderColNm[name],
    value: filterData[name] ?? "",
    sx: { width: 100 },
    onChange: (e) => setFilterData(o => ({ ...o, [name]: e.target.value })),
  }), [filterData])

  const setAllData = useCallback((originalData) => {
    const result = [...originalData]
    for (const data of result) {
      let sumOthAmnt = 0;
      let othExps = []
      let hasOth = false
      for (const exp of data.Exps) {
        if (exp.ExpId === 1) {
          data.E1 = +exp.Total
        } else {
          hasOth = true
          sumOthAmnt += +exp.Total
          othExps.push(exp.ExpNm)
        }
      }
      data.OthAmnt = hasOth ? sumOthAmnt : null
      data.OthExp = hasOth ? othExps.join(", ") : null
      data.Total = data.E1 || 0 + data.OthAmnt || 0
    }
    setDataTable(addIdForDataGrid(result, "JobOrdId"))
  }, [])

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getAddExpenseJobOrderMTB, filter).then(value => {
      if (value.data) {
        lastFilter = filter
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])

  const handleCellClick = useCallback(({row, field}) => {
    if (field === "ShowDetail") {
      selectedJobOrdId = row.JobOrdId
      setDialogJobOrderOpen(true)
    }
    // const postData = {
    //   InvId: row.InvId,
    //   JobOrdId: null,
    //   Field: field
    // }
    // ax.post(branchApiNm.getExpenseJobOrderDetail, postData).then(value => {
    //   if (value.data) {

    //     xjoDetailTable = addIdForDataGrid(value.data, "ExpJobOrdId")
    //     if (field === "OthOwnRcptAmnt") {
    //       xjoDetailTitle = `รายละเอียด ใบเสร็จบริษัทอื่นๆ ${row.InvNo}`
    //     } else if (field === "OthCusRcptAmnt") {
    //       xjoDetailTitle = `รายละเอียด ใบเสร็จลูกค้าอื่นๆ  ${row.InvNo}`
    //     }
    //     setDialogExpenseJobDetailOpen(true)
    //   }
    // })
  }, [])

  const handleCellEditCommit = useCallback(({ field, value, id }) => {
    console.log(field, value, id)
    if (field === "E1") {
      if (value === null) {
        const expJobOrdIds = []
        if (selectionModel.includes(id)) {
          const filteredData = dataTable.filter(row => selectionModel.includes(row.id))
          for (const data of filteredData) {
            const foundExp1 = data.Exps.find(exp => exp.ExpId === 1)
            if (foundExp1) {
              expJobOrdIds.push(foundExp1.ExpJobOrdId)
            }
          }
        } else {
          const foundData = dataTable.find(row => row.id === id)
          const foundExp1 = foundData.Exps.find(exp => exp.ExpId === 1)
          if (foundExp1) {
            expJobOrdIds.push(foundExp1.ExpJobOrdId)
          }
        }
        ax.post(expenseJobOrderApiNm.deleteExpenseJobOrder, { ExpJobOrdIds: expJobOrdIds, getArgs: null }).then(value => {
          if (value.data) {
            getData(lastFilter)
          }
        })
      } else {
        const updateIds = []
        const insertIds = []
        if (selectionModel.includes(id)) {
          const filteredData = dataTable.filter(row => selectionModel.includes(row.id))
          for (const data of filteredData) {
            const foundExp1 = data.Exps.find(exp => exp.ExpId === 1)
            if (foundExp1) {
              updateIds.push(foundExp1.ExpJobOrdId)
            } else {
              insertIds.push(data.JobOrdId)
            }
          }
        } else {
          const foundData = dataTable.find(row => row.id === id)
          const foundExp1 = foundData.Exps.find(exp => exp.ExpId === 1)
          if (foundExp1) {
            updateIds.push(foundExp1.ExpJobOrdId)
          } else {
            insertIds.push(foundData.JobOrdId)
          }
        }
        const expData = {
          IsCost: 0,
          ExpId: 1,
          VatPrct: 0,
          WhtPrct: 1,
          UntPr: value,
          Qty: 1,
          IsOwnRcpt: 1,
          Rmk: "",
        }
        const postData = {
          JobOrdIds: insertIds,
          ExpJobOrdIds: updateIds,
          ColValues: expData,
          getArgs: lastFilter
        }
        ax.post(branchApiNm.insertUpdateExpenseJobOrderBulk, postData).then(value => {
          if (value.data) {
            setAllData(value.data)
          }
        })
      }
    } else if (["FullWt", "TareWt", "Ref3"].includes(field)) {
      const postData = {
        JobOrdIds: [id],
        ColValues: { [field]: value || "" },
        getArgs: null
      }
      ax.post(jobOrderApiNm.updateJobOrderColumn, postData, false).then(axValue => {
        if (axValue.data) {
          //do nothing
          setDataTable(o=>{
            const result = [...o]
            const foundIndex = result.findIndex(row=>row.id === id)
            if(foundIndex !== -1){
              result[foundIndex][field] = value
            }
            return result
          })
        }
      })
    }

  }, [dataTable, selectionModel, ax, setAllData, getData])

  const getExpenseJob = useCallback(() => {
    if (selectionModel.length === 0) {
      alertError("กรุณาเลือกรายการที่ต้องการดึงค่าขนส่ง")
      return
    }
    const filteredData = dataTable.filter(row => selectionModel.includes(row.id))
    const jobIds = [...new Set(filteredData.map(row => row.JobId))];
    const postData = { JobIds: jobIds, ExpId: 1 }
    ax.post(expenseJobApiNm.getExpenseJob, postData).then(value => {
      if (value.data) {
        if (value.data.length === 0) {
          alertError("ไม่พบค่าขนส่งที่บันทึกไว้")
          return
        }
        const expJob = value.data
        const argsArr = []
        for (const row of filteredData) {
          const foundExp1 = row.Exps.find(exp => exp.UntPr)
          console.log("foundExp1::", foundExp1)
          if (foundExp1) continue

          const foundExpJob = expJob.find(exp => exp.JobId === row.JobId 
            && (!exp.ShpmTypId || exp.ShpmTypId === row.ShpmTypId))
          console.log("foundExpJob::", foundExpJob)
          if (foundExpJob) {
            let untPr = 0;
            if (!(+foundExpJob.UntPr) && foundExpJob.Rmk) {
              if (!row.TareWt || !row.FullWt) continue

              const dimWt = foundExpJob.Rmk.split(",")
              if (dimWt.length !== 2) continue

              const dim = dimWt[0].split(":")
              const wt = dimWt[1].split(":")
              if (dim.length !== 2 || wt.length !== 2) continue

              const dimCost = +dim[1] * +row.TareWt
              const wtCost = +wt[1] * +row.FullWt
              console.log(dimCost, wtCost)
              untPr = Math.max(dimCost, wtCost)
            } else if (+foundExpJob.UntPr) {
              untPr = +foundExpJob.UntPr
            }
            argsArr.push({
              JobOrdId: row.JobOrdId,
              IsCost: 0,
              ExpId: 1,
              VatPrct: 0,
              WhtPrct: 1,
              UntPr: untPr,
              Qty: 1,
              IsOwnRcpt: 1,
              Rmk: "",
            })
          }

        }
        if (argsArr.length === 0) {
          alertError("ค่าขนส่งที่บันทึกไว้ไม่ตรงกับรายการที่เลือก")
          return
        }
        ax.post(expenseJobOrderApiNm.insertUpdateExpenseJobOrderBulk, { argsArr: argsArr }).then(value => {
          if (value.data) {
            getData(lastFilter)
          }
        })
      }
    })
  }, [ax, dataTable, selectionModel, getData])

  const handleShowDHLFormat = useCallback(() => {
    curDataTable = dataTable
    setDialogDHLOpen(true)
  }, [dataTable])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])
  return (
    <BoxFC height="100%">
      <BoxFR>
        <Button variant="contained" onClick={getExpenseJob}><CloudDownload sx={{ mr: 1 }} />ดึงค่าขนส่งจากทีบันทึกไว้</Button>
        <Button variant="contained" onClick={handleShowDHLFormat}><ArticleRounded sx={{ mr: 1 }} />แสดงรูปแบบข้อมูล DHL</Button>
        <Box flex={1} />
        <ComboBox sx={{ width: 150 }} options={msData.cusCombo} label={jobOrderColNm.CusSNm}
          selectedId={filterData.CusId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, CusId: id }))}
        />
        <ComboBox sx={{ width: 150 }} options={msData.jobCombo} label={jobOrderColNm.JobSNm}
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
        />
        <TextField {...textFieldProps("ShpmTypId")} select label={shipmentColNm.ShpmTypNm}>
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          {msData.shipmentTypes.map((item) => (
            <MenuItem key={item.ShpmTypId} value={item.ShpmTypId}>{item.Name}</MenuItem>
          ))}
        </TextField>
        <ComboBox sx={{ width: 150 }} options={msData.driverCombo} label={jobOrderColNm.DrvNm}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <TextField {...textFieldProps("JobOrdId")} />
        {/* <TextField {...textFieldProps("InvNo")} /> */}
        <DateTimeSelect
          sx={{ width: 180 }}
          label={jobOrderColNm.AptTm}
          start={filterData.AptTmSt}
          end={filterData.AptTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, AptTmSt: st, AptTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <DateTimeSelect
          sx={{ width: 180 }}
          label={jobOrderColNm.FinDte}
          start={filterData.FinDteSt}
          end={filterData.FinDteEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, FinDteSt: st, FinDteEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <TextField {...textFieldProps("E1Sts")} select label="สถานะการลงค่าขนส่ง">
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          <MenuItem value={1}>ลงค่าขนส่งแล้ว</MenuItem>
          <MenuItem value={0}>ยังไม่ลงค่าขนส่ง</MenuItem>
        </TextField>
        {/* <DateTimeSelect
          sx={{ width: 180 }}
          label="วันที่แจ้งหนี้"
          start={filterData.InvDteSt}
          end={filterData.InvDteEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, InvDteSt: st, InvDteEn: en })) }}
          getData={() => { getData(filterData) }}
        /> */}
        <SearchButton onClick={() => getData(filterData)} />
        <ClearButton onClick={() => setFilterData({ ...clearFilterData })} />
      </BoxFR>
      <Box flex={1} >
        <DataGridCellExpand
          hideFooter
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rows={dataTable}
          columns={addExpenseJobOrderMTBColumns}
          onCellClick={handleCellClick}
          onCellEditCommit={handleCellEditCommit}
        />
      </Box>
      <DHLTableDialog
        datatable={curDataTable}
        dialogOpen={dialogDHLOpen}
        setDialogOpen={setDialogDHLOpen}
      />
      <DailyJobDialogV3
        dialogOpen={dialogJobOrderOpen}
        setDialogOpen={setDialogJobOrderOpen}
        selectedId={selectedJobOrdId}
      />
    </BoxFC>
  );
}

export default AddExpenseJobOrderMTB;