import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { Box, Button, Dialog, DialogContent, FormControl, FormControlLabel, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material'

import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import { initDialogData } from "pages/Menu4/ExpenseJob/initData"
import ComboBox from "components/ComboBox";
import { addIdForDataGrid, generateMenuComboItem, selectOnFocus, validate } from "utils";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { expenseJobNoJobCol } from "./columns";
import { alertConfirmDelete } from "components/Alert";
import { expenseJobApiNm, expenseJobColNm } from "constants/expenseJobConstant";

const ExpenseJobDialog = ({ jobId, dialogOpen, setDialogOpen, dataTable, setDataTable }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initDialogData })
  const validation = useMemo(() => ({
    ExpId: dialogData.ExpId === null,
    UntPr: dialogData.UntPr === '',
    Prty: dialogData.Prty === '',
  }), [dialogData]);

  const comboProp = useCallback((name, label, options, isRequire) => ({
    options: options,
    label: label,
    required: isRequire,
    sx: { width: "100%" },
    selectedId: dialogData[name],
    setSelectedId: (id) => setDialogData(oldState => {
      if (name === "ExpId") {
        console.log("id::", id)
        const found = msData.expenses.find(exp => exp.ExpId === id)
        console.log("found::", found)
        if (found) {
          return {
            ...oldState,
            ExpId: id,
            VatPrct: found.VatPrct,
            WhtPrct: found.WhtPrct,
            UntPr: found.UntPr,
            IsOwnRcpt: found.IsOwnRcpt,
            IsOprHide: found.IsOprHide,
          }
        } else {
          return { ...oldState, ExpId: id }
        }
      } else {
        return { ...oldState, [name]: id }
      }
    })
  }), [dialogData, msData, setDialogData])

  const dialogDataProp = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: expenseJobColNm[name],
    value: dialogData[name],
    error: validation[name],
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [dialogData, validation])

  const handleEditCommit = useCallback(async (params) => {
    console.log("params", params)

    if (jobId) {

      const postData = {
        ExpJobId: params.id,
        ColValues: {
          [params.field]: params.value
        }
      }
      const value = await ax.post(expenseJobApiNm.updateExpenseJobColumns, postData)
      if (!value.data) return
    }

    setDataTable(o => {
      const result = [...o]
      const foundIndex = result.findIndex(r => r.id === params.id)
      result[foundIndex][params.field] = params.value
      const { Amnt, VatPrct, WhtPrct } = result[foundIndex]
      result[foundIndex] = {
        ...result[foundIndex], [params.field]: params.value,
        PayAmnt: (((Amnt / (1 + (VatPrct / 100))) * (1 - (WhtPrct / 100)))
          + (((Amnt / (1 + (VatPrct / 100))) * VatPrct) / 100))
      }
      return result
    })
  }, [ax, jobId, setDataTable])

  const handleAddExpenseJob = useCallback(async () => {
    if (!validate(validation)) return

    const postData = {
      ...dialogData,
      JobId: jobId,
      getArgs: { JobId: jobId }
    }

    if (jobId) {
      const value = await ax.post(expenseJobApiNm.insertExpenseJob, postData)
      if (!value.data) return
      setDataTable(addIdForDataGrid(value.data, "ExpJobId"))
    } else {
      setDataTable(o=>[...o, {
        ...dialogData, 
        No: o.length + 1, 
        id: o.length + 1,
        ExpNm: msData.expObj[dialogData.ExpId]?.ExpNm || "",
      }])
    }

  }, [ax, jobId, dialogData, validation, setDataTable, msData.expObj])

  const handleDeleteRow = useCallback((row) => {
    alertConfirmDelete(async () => {
      if (jobId) {
        const value = await ax.post(expenseJobApiNm.deleteExpenseJob, { ExpJobId: row.id })
        if (!value.data) return
      }
      setDataTable(o => {
        return o.filter(r => r.id !== row.id).map((r, i) => ({ ...r, No: i + 1 }))
      })
    })
  }, [ax, setDataTable, jobId])

  const expenseJobNoJobColMemo = useMemo(() => expenseJobNoJobCol(handleDeleteRow), [handleDeleteRow])

  useEffect(() => {
    if (dialogOpen) {
      if (jobId) {
        ax.post(expenseJobApiNm.getExpenseJob, { JobId: jobId }).then(value => {
          if (value.data) {
            setDataTable(addIdForDataGrid(value.data, "ExpJobId"))
          }
        })
      } else {
        setDialogData({...initDialogData})
      }
    } 
  }, [dialogOpen, ax, jobId, setDataTable])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 1680,
          minWidth: 1680,
          width: 1680,
        }
      }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"กำหนด คขช. แจ้งหนี้"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR mt={2} sx={{ alignItems: "stretch", justifyContent: "center", gap: 2 }} width="100%">
          <Box display='flex' flexDirection='column' gap={1} key={dialogData.ExpId} width={380} >
            <Typography variant="h6">เพิ่มค่าใช้จ่าย</Typography>
            <BoxFR>
            <ComboBox {...comboProp("ExpId", expenseJobColNm.ExpNm, msData.expCombo, true)} />
              {/* <TextField {...dialogDataProp('IsCost')} select fullWidth label="ต้นทุน-รายได้(เก็บเงินลูกค้าได้)" >
                <MenuItem value={1}>ต้นทุน</MenuItem>
                <MenuItem value={0}>รายได้</MenuItem>
              </TextField> */}

            </BoxFR>
            <BoxFR>
              <ComboBox {...comboProp("JobId", "สถานที่", msData.poiCombo, false)} />
              <TextField select {...dialogDataProp("ShpmTypId")} fullWidth
                value={dialogData.ShpmTypId || ""}
                onChange={e => setDialogData(o => ({ ...o, ShpmTypId: e.target.value || null }))}>
                <MenuItem value="">ไม่ระบุ</MenuItem>
                {generateMenuComboItem(msData.ShpmTypCombo)}
              </TextField>
            </BoxFR>
            <BoxFR>
              <TextField select {...dialogDataProp("ContSize")} fullWidth >
                <MenuItem value="">ไม่ระบุ</MenuItem>
                {generateMenuComboItem(msData.contSizeCombo)}
              </TextField>
              <TextField select {...dialogDataProp("ContTyp")} fullWidth >
                <MenuItem value="">ไม่ระบุ</MenuItem>
                {generateMenuComboItem(msData.contTypCombo)}
              </TextField>
            </BoxFR>
            <BoxFR>
              <ComboBox {...comboProp("TlrTypId", "ประเภทหาง", msData.tlrTypCombo, false)} />
              <TextField {...dialogDataProp('UntPr')} fullWidth type="number" onFocus={selectOnFocus} required
                onChange={(e) => { setDialogData((oldState) => ({ ...oldState, UntPr: e.target.value })) }}
                inputProps={{ style: { textAlign: "right" } }}
                InputProps={{ endAdornment: <InputAdornment position="end">บาท</InputAdornment> }} />
            </BoxFR>

            <BoxFR>
              <TextField {...dialogDataProp('WhtPrct')} type="number" onFocus={selectOnFocus}
                inputProps={{ style: { textAlign: "right" } }} fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }} />

              <TextField {...dialogDataProp('VatPrct')} type="number" onFocus={selectOnFocus}
                inputProps={{ style: { textAlign: "right" } }} fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }} />
            </BoxFR>
            {dialogData.IsCost === 0 &&
              <FormControl component="fieldset">
                <FormLabel component="legend">{expenseJobColNm.IsOwnRcpt}</FormLabel>
                <RadioGroup row name='IsOwnRcpt' value={dialogData.IsOwnRcpt}
                  onChange={(e) => { setDialogData((oldState) => ({ ...oldState, IsOwnRcpt: e.target.value })) }}>
                  <FormControlLabel value={1} control={<Radio />} label="ใบเสร็จบริษัท" />
                  <FormControlLabel value={0} control={<Radio />} label="ใบเสร็จลูกค้า" />
                </RadioGroup>
              </FormControl>
            }
            <BoxFR>
              <TextField {...dialogDataProp('Prty')} required fullWidth onFocus={selectOnFocus} />
              <Box width="100%" />
            </BoxFR>
            <TextField {...dialogDataProp('Rmk')} multiline rows={3} />
            <Button fullWidth variant="contained" onClick={handleAddExpenseJob}>เพิ่มค่าใช้จ่าย</Button>
          </Box>
          <BoxFC sx={{ gap: 1 }} flex={1} height={600}>
            <Typography variant="h6">รายการค่าใช้จ่าย</Typography>
            <Box flex={1}>
              <DataGridCellExpand
                hideFooter
                hideToolbar
                rows={dataTable}
                columns={expenseJobNoJobColMemo}
                onCellEditCommit={handleEditCommit}
              />
            </Box>
          </BoxFC>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText={""} cancelText="ปิด"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(ExpenseJobDialog)