import { DeleteRounded, NoteAddRounded, SystemUpdateAltRounded } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { BoxFR } from "components/BoxCustom";
import React, { useCallback } from "react";
import { handleFileDrop } from "utils/handleFileDrop";
import isFileOverSize from "utils/isFileOverSize";


/**
 * filedata is file obj from input file 
 * or obj form network with this obj
 * {
 *   imgLink: string,
 *   label: string,
 * }
 */
const FileDropOne = ({ fileData, setFileData, sx }) => {

  const handleFileDataFileClick = useCallback((link) => () => {
    window.open(link, "_blank")
  }, [])

  const handleRemoveFileData = useCallback(() => {
    setFileData(null)
  }, [setFileData])

  const handleDropFile = useCallback((e) => {
    handleFileDrop(e, (files) => {
      if (!files || files.length === 0) return
      setFileData(files[0])
    })
  }, [setFileData])

  const handleFileDataFileChange = useCallback((e) => {
    const files = Array.from(e.target.files)
    console.log({ files })
    if (!files || files.length === 0 || isFileOverSize(files[0])) return
    const fileNameOnly = files[0].name.substring(0, files[0].name.lastIndexOf('.')) || files[0].name
    files[0].label = fileNameOnly
    setFileData(files[0])
  }, [setFileData])

  const handleDragOver = useCallback((e) => {
    /** Must prevent then can use onDrop */
    e.preventDefault()
  }, [])

  return (
    <BoxFR sx={{ ...sx, justifyContent: "space-between" }} borderRadius={1} bgcolor={grey[200]} py={0.5} px={1}
      onDrop={handleDropFile} onDragOver={handleDragOver}>
      {fileData ?
        <Button variant="outlined" size="small" onClick={handleFileDataFileClick(fileData.imgLink)}>
          <Typography noWrap component="span" variant="caption" >{fileData.label}</Typography>
        </Button>
        :
        <BoxFR>
          <SystemUpdateAltRounded sx={{ color: grey[500] }} /><Typography variant="caption" color={grey[600]}>
            Drop file here
          </Typography>
        </BoxFR>
      }
      {fileData ?
        <Button color="error" variant="contained" size="small" sx={{ minWidth: 0, p: 0.5 }} 
          onClick={handleRemoveFileData}>
          <DeleteRounded />
        </Button>
        :
        <Button variant="contained" size="small" component="label" sx={{ minWidth: 0, p: 0.5 }}>
          <NoteAddRounded />
          <input type="file" hidden onChange={handleFileDataFileChange} />
        </Button>
      }
    </BoxFR>
  )
}

export default FileDropOne;