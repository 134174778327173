import { Box, Button, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useState } from "react";
import { useContext } from "react";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { columns } from "./columns";
import CheckboxFormControl from "components/CheckboxFormControl";
import { initRefColumnName } from "../constant";
import { useMemo } from "react";
import { initRefData } from "./initData";

const SelectJob = ({sx, selectedJobData, setSelectedJobData}) => {
  
  console.log({selectedJobData})
  const {msData} = useContext(GlobalStateContext)
  const [jobCombo, setJobCombo] = useState([])
  const [selectedCusId, setSelectedCusId] = useState(null) 
  const [selectedJobId, setSelectedJobId] = useState(null) 
  const [shipmentData, setShipmentData] = useState({...initRefData})
  const [jobOrderData, setJobOrderData] = useState({...initRefData})
  
  const dataTable = useMemo(()=>selectedJobData.map((item, index)=>{
    console.log("item::", item)
    return {
      No: index+1,
      id: item.id,
      label: item.label,
      shipment_Ref1:  item.shipment.Ref1,
      shipment_Ref2:  item.shipment.Ref2,
      job_order_Ref1:  item.job_order.Ref1,
      job_order_Ref2:  item.job_order.Ref2
    }
  }), [selectedJobData])
  const refColumnName = useMemo(()=>{
    return msData.serverData.RefColumnName || initRefColumnName
  }, [msData.serverData.RefColumnName])

  const comboProp = useCallback((name, label, value, options) => ({
    name: name,
    sx: { flex: 1 },
    options: options,
    label: label,
    selectedId: value
  }), [])
  
  const checkboxShipmentProps = useCallback((name)=>({
    label:refColumnName.shipment[name],
    checked: shipmentData[name],
    onChange: (e) =>setShipmentData(o=>({...o, [name]: e.target.checked}))
  }), [refColumnName.shipment, shipmentData])

  const checkboxJobOrderProps = useCallback((name)=>({
    label:refColumnName.job_order[name],
    checked: jobOrderData[name],
    onChange: (e) =>setJobOrderData(o=>({...o, [name]: e.target.checked}))
  }), [refColumnName.job_order, jobOrderData])

  const handleDeleteRow = useCallback((id) => (e) => {
    e.stopPropagation();
    setSelectedJobData({id}, true)
  }, [setSelectedJobData])

  
  const handleSelecteCusCombo = useCallback((id) => {
    setJobCombo(msData.jobCombo.filter(item => item.refId === id))
    setSelectedCusId(id)
  }, [msData.jobCombo])

  const handleAddJob = useCallback(()=>{
    console.log("in handleAddJob")
    const job = jobCombo.find(item=>item.id === selectedJobId)
      console.log({job})
      job.JobId = job.id
      job.shipment = {...shipmentData}
      job.job_order = {...jobOrderData}
      console.log({job})
      setSelectedJobData([job])

    
  }, [selectedJobId, jobCombo, jobOrderData, shipmentData, setSelectedJobData])

  const handleAddJobAll = useCallback(()=>{
    const jobs = jobCombo.map(item=>({
      ...item,
      JobId: item.id,
      shipment: {...shipmentData},
      job_order: {...jobOrderData}
    }))
    console.log({jobs})
    setSelectedJobData(jobs)
  }, [jobCombo, setSelectedJobData, jobOrderData, shipmentData])
  
  console.log({dataTable})
  return (
    <BoxFC sx={sx}>
      <BoxFC >
        <BoxFR>
          <Typography sx={{width:100}}>ใบแจ้งลาก</Typography>
          <CheckboxFormControl {...checkboxShipmentProps("Ref1")}/>
          <CheckboxFormControl {...checkboxShipmentProps("Ref2")}/>
        </BoxFR>
        <BoxFR>
          <Typography sx={{width:100}}>ใบงาน</Typography>
          <CheckboxFormControl {...checkboxJobOrderProps("Ref1")}/>
          <CheckboxFormControl {...checkboxJobOrderProps("Ref2")} />
        </BoxFR>
        <BoxFR>
          <ComboBox {...comboProp("CusId", "ลูกค้า", selectedCusId, msData.cusCombo)} setSelectedId={handleSelecteCusCombo} />
          <Button variant="contained" onClick={handleAddJobAll}><AddCircleRoundedIcon /></Button>
          <Box width={50}/>
          <ComboBox {...comboProp("JobId", "งาน", selectedJobId, jobCombo)} setSelectedId={id => setSelectedJobId(id)} />
          <Button variant="contained" onClick={handleAddJob}><AddCircleRoundedIcon /></Button>
        </BoxFR>
        <Typography fontWeight="bold" >งานที่เลือก</Typography>
      </BoxFC>
      <Box flex={1} mt={-2}>
        <DataGridCellExpand
          hideToolbar
          hideFooter
          rows={dataTable}
          columns={columns(handleDeleteRow, refColumnName)} />
      </Box>
    </BoxFC>
  )
}

export default React.memo(SelectJob)