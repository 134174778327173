
import { alertSuccess } from "components/Alert";
import SaveButton from "components/buttons/SaveButton";
import { coreApiNm } from "constants/serverConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useContext } from "react";
import { loadMSData } from "utils";

const SaveDataButton = ({ sx, name, data, checkData}) =>{
  const {ax, msData:{serverData}, setMSData} = useContext(GlobalStateContext)


  const newServerData = {...serverData, [name]: data}
  const handleSaveData = () =>{
    if(checkData && !checkData(data)) return 
    ax.post(coreApiNm.saveServerData, newServerData).then(value=>{
      if(value.data){
        // setMSData(o=>({...o, serverData: newServerData}))
        loadMSData(ax, setMSData)
        alertSuccess("บันทึกเรียบร้อยแล้ว")
      }
    })
  }

  return (
    <SaveButton sx={sx}  onClick={handleSaveData}/>
  )
}

export default SaveDataButton