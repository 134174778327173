import { Box, Button } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { getSumColorClassName, sxSumColorClass } from 'utils/highlightSumColor';
import { columns } from './columns';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { addIdForDataGrid } from 'utils';
import { red } from '@mui/material/colors';
import PCPeriodDriverDialog from './PCPeriodDriverDialog';
import { pettyCashApiNm } from 'pages/Menu1/PettyCash/constant';

let selectedId = null;
// let lastFilter = null

const PCPeriodDriver = () => {
  const { ax } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })

  const getData = useCallback((filter) => {

    ax.post(pettyCashApiNm.getPettyCashPeroid, filter).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "PCPrdId"))
        // lastFilter = filter
      }
    })
  }, [ax])

  const handleCellClick = useCallback((params) => {
    if (params.field === 'ShowDetail') {
      selectedId = params.id
      setDialogOpen(true)
    }
  }, [])
  const handleRowDoubleClick = useCallback((params) => {
    selectedId = params.id
    setDialogOpen(true)
  }, [])

  // const onFinish = useCallback((data) => {
  //   if (data) {
  //     setDataTable(addIdForDataGrid(data, 'PCPrdId'))
  //   } else {
  //     getData(lastFilter)
  //   }
  // }, [getData])

  const handleAddBalance = useCallback(() => {
    selectedId = null
    setDialogOpen(true)
  }, [])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        <Button variant='contained' onClick={handleAddBalance}>เพิ่มรายกร</Button>
        <Box flex={1} />
        <BoxFR>
          <DateTimeSelect sx={{ width: 180 }} label='วันที่ทำรายการ' start={filterData.PCPrdTmSt} end={filterData.PCPrdTmEn}
            setSelectDate={(st, en) => { setFilterData(o => ({ ...o, PCPrdTmSt: st, PCPrdTmEn: en })) }}
            getData={() => { getData(filterData) }}
          />
          <SearchButton onClick={(() => getData(filterData))} />
          <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
        </BoxFR>
      </BoxFR>
      <Box height='100%' sx={{
        ...sxSumColorClass,
        "& .has-diff": { bgcolor: red[50], "&:hover": { bgcolor: red[100] } }
      }}>
        <DataGridCellExpand
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          hideFooter
          rows={dataTable}
          columns={columns}
          onRowDoubleClick={handleRowDoubleClick}
          onCellClick={handleCellClick}
          getRowClassName={getSumColorClassName}
        />
      </Box>
      <PCPeriodDriverDialog pcPrdId={selectedId} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
    </BoxFC>
  );
}

export default PCPeriodDriver