import { jsPDF } from "jspdf";
import { numberFormat } from "utils";
// import { toBahtText } from "utils/toBahtText";
import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"
import { printHeader } from "./pirntHeader";
import dayjs from "dayjs";
import { salaryDriverColNm } from "branch/pages/DriverSalary/constant";
import { blue, yellow } from "@mui/material/colors";


const salColNm = {
  ...salaryDriverColNm,
  SumInc: "รวมรายได้",
  SumTrip: "ค่าเที่ยว+อื่นๆ",
  SumDeduct: "รวมรายการหัก"
}
export const printDriverSalary = ({ dialogData, dataTable, msData }) => {
  console.log("printDriverSalary::", dialogData, dataTable, msData)
  const companyData = msData.serverData.CompanyData
  const startY = 0.3
  const startX = 0.5
  const pageSize = [8.3, 11.7];
  const lineHeight = 0.21
  const contentSize = 14;
  let yPos = startY
  let xPos = startX
  const doc = new jsPDF({
    orientation: "potrait",
    unit: "in",
    format: pageSize
  });
  doc.setLineWidth(0.01)
  doc.setFont("THSarabun")

  const colNoW = 0.35
  const colAptTmW = 0.45
  // const colContNoW = 2
  const colE88 = 0.6
  const colLocW = 2
  const colTripW = 0.8
  const colOthW = 0.8
  const colTotalW = 0.8
  const colOthDtlW = 1.5
  const dataBoxW = colNoW + colAptTmW  + colE88 + colLocW + colTripW + colTotalW + colOthW + colOthDtlW

  const MAX_PAGE_SIZE_1 = 30
  const MAX_PAGE_SIZE_2 = 45
  const totalPage = dataTable.length <= MAX_PAGE_SIZE_1 ? 1 : Math.ceil((dataTable.length - 35) / MAX_PAGE_SIZE_2) + 1
  console.log("totalPage::", totalPage)
  // const totalPage = Math.ceil(dataTable.length / MAX_PAGE_SIZE)
  // console.log("dataTable.length::", dataTable.length)
  // let index = 0
  const startTableDetailX = startX
  for (let pageNo = 1; pageNo <= totalPage; pageNo++) {
    console.log("pageNo::", pageNo)
    // let maxRow = MAX_PAGE_SIZE

    doc.setFont("THSarabun")
    if (pageNo !== 1) {
      doc.addPage(pageSize, "portrait")
    }
    xPos = startX
    yPos = startY
    yPos = printHeader({ jsPDFDoc: doc, companyData, startX: xPos - 0.2, startY: yPos })

    //---------------------------------draw top right -------------------------------------
    yPos = startY + 0.2
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(20)
    doc.text("รายละเอียดเงินเดือน", 7, yPos, { align: "center" })

    doc.setFont("THSarabun", "bold")
    doc.setFontSize(contentSize)
    const lineHeightTopRight = lineHeight + lineHeight / 5
    const yPosTopRight = yPos + lineHeightTopRight
    const xPosTopRight = 6
    xPos = xPosTopRight
    yPos = yPosTopRight

    doc.text("รอบจ่ายเงิน : ", xPos, yPos)
    yPos += lineHeightTopRight
    doc.text("พนักงาน: ", xPos, yPos)
    yPos += lineHeightTopRight
    doc.setFont("THSarabun", "normal")
    yPos = yPosTopRight
    xPos += 0.8
    doc.text(dayjs(dialogData.SalDte).format("MMMM YYYY"), xPos, yPos)
    yPos += lineHeightTopRight
    doc.setFontSize(contentSize + 3)
    doc.setFont("THSarabun", "bold")
    doc.text(dialogData.DrvNm, xPos - 0.2, yPos)
    doc.setFontSize(contentSize)
    doc.setFont("THSarabun", "normal")
    yPos += lineHeightTopRight
    if (totalPage > 1) {
      doc.text(`หน้า ${pageNo} / ${totalPage}`, xPos, yPos)
    }

    yPos += lineHeight + 0.15

    const startTableX = startX + 0.05
    const numberColWidth = 2
    const gap = 0.2
    const summaryTableWidth = numberColWidth + gap
    const drawNumber = (name, xPos, yPos, isMinus) => {
      doc.text(salColNm[name] || "-", xPos, yPos)
      doc.text(`${isMinus ? "- " : ""}${numberFormat(+dialogData[name])} บาท`, xPos + numberColWidth, yPos, { align: "right" })
    }

    const drawSummaryTable = (startTableX, startTableY) => {
      let tableYPos = startTableY - lineHeight
      let tableXPos = startTableX - 0.1
      doc.setFillColor(blue[100])
      doc.rect(tableXPos, tableYPos, summaryTableWidth, lineHeight + 0.1, "F")
      doc.line(tableXPos, tableYPos, tableXPos + summaryTableWidth, tableYPos)
      tableYPos += lineHeight + 0.1
      doc.line(tableXPos, tableYPos, tableXPos + summaryTableWidth, tableYPos)
      tableYPos += (lineHeight * 8) + 0.1

      doc.setFillColor(yellow[100])
      doc.rect(tableXPos, tableYPos, summaryTableWidth, lineHeight + 0.1, "F")
      doc.line(tableXPos, tableYPos, tableXPos + summaryTableWidth, tableYPos)
      tableYPos += lineHeight + 0.1
      doc.line(tableXPos, tableYPos, tableXPos + summaryTableWidth, tableYPos)
      doc.line(tableXPos, startTableY - lineHeight, tableXPos, tableYPos)
      doc.line(tableXPos + summaryTableWidth, startTableY - lineHeight, tableXPos + summaryTableWidth, tableYPos)
    }

    if (pageNo === 1) {
      const startYData = yPos
      xPos = startTableX
      yPos = startYData
      drawSummaryTable(xPos, yPos)
      doc.setFont("THSarabun", "bold")
      doc.text("รายได้", xPos + (numberColWidth) / 2, yPos, { align: "center" })
      yPos += lineHeight + 0.1
      // /const startInTableData = yPos
      doc.setFont("THSarabun", "normal")
      drawNumber("SalAmnt", xPos, yPos)
      yPos += lineHeight
      drawNumber("SumTrip", xPos, yPos)
      yPos += lineHeight
      drawNumber("IncAmnt1", xPos, yPos)
      yPos += lineHeight
      drawNumber("IncAmnt2", xPos, yPos)
      yPos += lineHeight
      drawNumber("IncAmnt3", xPos, yPos)
      yPos += lineHeight
      drawNumber("IncAmnt4", xPos, yPos)
      yPos += lineHeight
      drawNumber("IncAmnt5", xPos, yPos)
      yPos += lineHeight
      drawNumber("OthAmnt", xPos, yPos)

      yPos = startYData + (lineHeight * 9) + 0.2
      doc.setFont("THSarabun", "bold")
      doc.text("รวมรายได้", xPos, yPos)
      doc.text(`${numberFormat(dialogData.SumInc)} บาท`, xPos + numberColWidth, yPos, { align: "right" })
      if (dialogData.OthDscp) {
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentSize - 2)
        doc.text(`*รายได้อื่นๆ : ${dialogData.OthDscp}`, xPos + numberColWidth, yPos + lineHeight + 0.04, { align: "right" })
        doc.setFontSize(contentSize)
      }

      const tableYPos = yPos += lineHeight

      xPos = startTableX + numberColWidth + gap * 3
      yPos = startYData
      drawSummaryTable(xPos, yPos)
      doc.setFont("THSarabun", "bold")
      doc.text("รายการหัก", xPos + (numberColWidth) / 2, yPos, { align: "center" })
      yPos += lineHeight + 0.1
      doc.setFont("THSarabun", "normal")
      drawNumber("DeSSAmnt", xPos, yPos)
      yPos += lineHeight
      drawNumber("DeSecDepAmnt", xPos, yPos)
      yPos += lineHeight
      drawNumber("DeTfcFineAmnt", xPos, yPos)
      yPos += lineHeight
      drawNumber("DeAmnt1", xPos, yPos)
      yPos += lineHeight
      drawNumber("DeAmnt2", xPos, yPos)
      yPos += lineHeight
      drawNumber("DeOthAmnt", xPos, yPos)

      yPos = startYData + (lineHeight * 9) + 0.2
      doc.setFont("THSarabun", "bold")
      doc.text("รวมรายการหัก", xPos, yPos)
      doc.text(`${numberFormat(dialogData.SumDeduct)} บาท`, xPos + numberColWidth, yPos, { align: "right" })
      if (dialogData.DeOthDscp) {
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentSize - 2)
        doc.text(`*หักอื่นๆ : ${dialogData.DeOthDscp}`, xPos + numberColWidth, yPos + lineHeight + 0.04, { align: "right" })
        doc.setFontSize(contentSize)
      }




      xPos = startTableX + numberColWidth * 2 + gap * 6
      yPos = startYData
      drawSummaryTable(xPos, yPos)
      doc.setFont("THSarabun", "bold")
      doc.text("สรุปยอด", xPos + (numberColWidth) / 2, yPos, { align: "center" })
      yPos += lineHeight + 0.1
      doc.setFont("THSarabun", "normal")
      drawNumber("SumInc", xPos, yPos)
      yPos += lineHeight
      drawNumber("SumDeduct", xPos, yPos, true)
      yPos += lineHeight
      drawNumber("DrvAdvPay", xPos, yPos, true)

      yPos = startYData + (lineHeight * 9) + 0.2
      doc.setFont("THSarabun", "bold")
      doc.text("ยอดคงเหลือ", xPos, yPos)
      const { SumInc, SumDeduct, DrvAdvPay } = dialogData
      doc.text(`${numberFormat(SumInc - SumDeduct - +(DrvAdvPay || 0))} บาท`, xPos + numberColWidth, yPos, { align: "right" })

      yPos = tableYPos + 0.18

      xPos = startTableDetailX
      doc.text("รายละเอียดวิ่งงาน", xPos, yPos)
    }

    //-------------------------------------- Box Header --------------------------------------------------------

    xPos = startTableDetailX
    if (pageNo === 1) {
      yPos += lineHeight - 0.1
    } else {
      yPos -= lineHeight
    }
    doc.setFillColor(blue[100])

    doc.rect(xPos, yPos, dataBoxW, lineHeight + 0.1, "F")
    doc.line(xPos, yPos, xPos + dataBoxW, yPos)
    // doc.lines([[dataBoxW, 0], [0, lineHeight * 2], [dataBoxW * -1, 0], [0, lineHeight * 2 * -1]], startTableDetailX, yPos, [1, 1], 'F', true)
    // doc.line(startTableDetailX, yPos, startTableDetailX + dataBoxW, yPos)
    // doc.lines([[dataBoxW, 0], [0, dataBoxH], [dataBoxW * -1, 0], [0, dataBoxH * -1]], startTableDetailX, yPos, [1, 1], 'S', true)
    // xPos = startTableDetailX + invNoColW
    // doc.line(xPos, yPos, xPos, yPos + dataBoxH)
    // xPos += invDteColW
    // doc.line(xPos, yPos, xPos, yPos + dataBoxH)
    // xPos += ownRcptColW
    // doc.line(xPos, yPos, xPos, yPos + dataBoxH)
    // xPos += cusRcptColW
    // doc.line(xPos, yPos, xPos, yPos + dataBoxH + lineHeight * 2)
    //------------------------------------- Text Header ----------------------------------------------------------
    yPos += lineHeight
    xPos = startTableDetailX
    const drawHeader = (label, width, xPos, yPos) => {
      doc.text(label, xPos + width / 2, yPos, { align: "center" })
      // xPos += width
      return xPos + width
    }
    const drawText = (text, width, xPos, yPos) => {
      doc.text(text, xPos + 0.1, yPos,)
      // xPos += width
      return xPos + width
    }
    // doc.text("Job No.", xPos + jobNoColW / 2, yPos, { align: "center" })
    // xPos += jobNoColW
    xPos = drawHeader("No.", colNoW, xPos, yPos)
    xPos = drawHeader("วันที่", colAptTmW, xPos, yPos)
    // xPos = drawHeader("หมายเลขตู้", colContNoW, xPos, yPos)
    xPos = drawHeader("สถานที่", colLocW, xPos, yPos)
    xPos = drawHeader("ค่าเที่ยว", colTripW, xPos, yPos)
    xPos = drawHeader("ค่ารัดตึง", colE88, xPos, yPos)
    xPos = drawHeader("ค่าอื่นๆ", colOthW, xPos, yPos)
    xPos = drawHeader("รวม", colTotalW, xPos, yPos)
    xPos = drawHeader("หมายเหตุ", colOthDtlW, xPos, yPos)
    yPos += 0.1
    doc.line(startTableDetailX, yPos, startTableDetailX + dataBoxW, yPos)
    //------------------------------------- Data ----------------------------------------------------------
    const drawNumberTable = (number, width, xPos, yPos) => {
      doc.text(`${numberFormat(number)}`, xPos + width, yPos, { align: "right" })
      // xPos += width
      return xPos + width
    }
    doc.setFont("THSarabun", "normal")
    yPos += lineHeight
    xPos = startTableDetailX
    doc.setFontSize(contentSize - 1)
    const startRow = pageNo === 1 ? 0 : MAX_PAGE_SIZE_1 + (pageNo - 2) * MAX_PAGE_SIZE_2
    const endRow = pageNo === 1 ? MAX_PAGE_SIZE_1 : MAX_PAGE_SIZE_1 + (pageNo -1) * MAX_PAGE_SIZE_2
    // console.log("pageNo::", pageNo, "startRow::", startRow, "endRow::", endRow)
    for(let i = startRow; i<endRow; i++){
      if(i>=dataTable.length) break
      const data = dataTable[i]
    // for (const data of dataTable) {
      xPos = startTableDetailX
      if (data.id === 0) {
        doc.setFont("THSarabun", "bold")
        doc.setFillColor(yellow[100])
        doc.rect(startTableDetailX, yPos - 0.1, dataBoxW, lineHeight + 0.1, "F")
        doc.line(startTableDetailX, yPos - 0.1, startTableDetailX + dataBoxW, yPos - 0.1)
        yPos += 0.1
      }
      const { TakePlc, Loc, RtnPlc } = data
      xPos = drawHeader(`${data.No || ""}.`, colNoW, xPos, yPos)
      xPos = drawHeader(`${data.AptTm ? dayjs(data.AptTm).format("DD/MM") : "-"}`, colAptTmW, xPos, yPos)
      // xPos = drawText(`${data.ContNo || "-"}`, colContNoW, xPos, yPos)
      xPos = drawText(data.id === 0 ? "" : `${TakePlc?.substring(0, 7) || ""}-${Loc?.substring(0, 10) || ""}-${RtnPlc?.substring(0, 7 || "")}`, colLocW, xPos, yPos)
      xPos = drawNumberTable(+data.E87, colTripW, xPos, yPos)
      xPos = drawNumberTable(`${+data.E88 || "-"}`, colE88, xPos, yPos)
      xPos = drawNumberTable(+data.OthAmnt, colOthW, xPos, yPos)
      xPos = drawNumberTable(+data.ExpTotal, colTotalW, xPos, yPos)
      xPos = drawText(data.id === 0 ? "" : `${data.OthDscp || "-"}`, colOthDtlW, xPos, yPos)
      yPos += lineHeight
    }

  }
  doc.setFontSize(contentSize)
  doc.line(startTableDetailX, yPos - 0.1, startTableDetailX + dataBoxW, yPos - 0.1)
  yPos += (lineHeight * 2) + 0.1

  doc.setFont("THSarabun", "normal")
  const signWidth = 1.5
  const startsignX = startTableDetailX + 0.6
  const col1 = startsignX
  const col2 = startsignX + 5

  doc.line(col1, yPos, col1 + signWidth, yPos)
  doc.line(col2, yPos, col2 + signWidth, yPos)
  yPos += lineHeight
  doc.text("ผู้จัดทำ", col1 + signWidth / 2, yPos, { align: "center" })
  doc.text("ผู้ตรวจสอบ", col2 + signWidth / 2, yPos, { align: "center" })

  doc.output('dataurlnewwindow');
}