import { TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React from "react";
import SaveDataButton from "./SaveDataButton";

const Feature = ({ feature, setFeature }) => {

  console.log("feature:::", feature)
  return (
    <BoxFC>
      <Typography variant="h6">ตัวเลือก Features</Typography>
      {/* <BoxFC pl={2} width={500} sx={{gap:0}}>
        <Typography fontWeight="bold">รับ-จ่าย ใบงาน</Typography>
        <BoxFR >
          <Typography sx={{width: 250}}>แสดงค่าเที่ยวใน รับ-จ่าย ใบงาน</Typography>
          <FormControlLabel control={<Switch  
            value={feature.JobOrderTransferDriverIncome?true:false} 
            onChange={(e)=>setFeature(o=>({...o, JobOrderTransferDriverIncome: e.target.checked?1:0}))} />} 
            label={feature.JobOrderTransferDriverIncome? "แสดง": "ไม่แสดง"} 
            />
          
        </BoxFR>
      </BoxFC> */}
      <BoxFC pl={2} width={500} sx={{ gap: 1 }}>
        <Typography fontWeight="bold">ตัวเลือกลูกค้า-งาน</Typography>
        <BoxFR >
          <Typography sx={{ width: 250 }}>จำนวนลูกค้าที่ใช้บ่อย</Typography>
          <TextField size="small" sx={{width: 80}} type="number" value={feature.NumCusFrequent ?? 10}
            onChange={e => setFeature(o => ({ ...o, NumCusFrequent: e.target.value }))} />
        </BoxFR>
        <BoxFR >
          <Typography sx={{ width: 250 }}>จำนวนงานที่ใช้บ่อย</Typography>
          <TextField size="small" sx={{width: 80}} type="number" value={feature.NumJobFrequent ?? 10}
            onChange={e => setFeature(o => ({ ...o, NumJobFrequent: e.target.value }))} />
        </BoxFR>
      </BoxFC>
      <SaveDataButton sx={{ width: 150 }} name="Feature" data={feature} />
    </BoxFC>
  )
}

export default React.memo(Feature)