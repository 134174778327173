import {  Button, CircularProgress, Dialog, DialogContent, InputAdornment, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { initAddDriverTripDialogData } from "./initData"
import { pettyCashColNm } from "pages/Menu1/PettyCash/constant"
import { expensePOIApiNm } from "pages/Menu4/ExpensePOI/constant"
import { shipmentColNm } from "constants/shipmentConstant"
import ComboBox from "components/ComboBox"
import { selectOnFocus } from "utils"
import { jobOrderApiNm } from "constants/jobOrderConstant"
import { alertConfirmDelete, alertError, alertSuccess } from "components/Alert"
import { SaveRounded } from "@mui/icons-material"
import ExpensePOIDialog from "pages/Menu4/ExpensePOI/ExpensePOIDialog"
import dayjs from "dayjs"
import { branchApiNm } from "branch/constant/branchApiNm"

let isPreventUpdateAmnt = false
let initExpensePOIData = {}
const AddDriverTripDailog = ({ pcItmId, dialogOpen, setDialogOpen, onFinish, dataRow, suggestDrvIds, lastFilter, oData }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initAddDriverTripDialogData })
  const [isLoadingUntPr, setIsLoadingUntPr] = useState(false)
  const [dialogExpensePOIOpen, setDialogExpensePOIOpen] = useState(false)

  const tripExps = useMemo(() => {
    const top = msData.expCombo.filter(exp => exp.refId === 6)
    const other = msData.expCombo.filter(exp => exp.refId !== 6)
    return [
      ...top,
      ...other
    ]
  }, [msData.expCombo])
  const suggestAccCombo = useMemo(() => {
    if (!suggestDrvIds) return msData.accountAllCombo
    const top = msData.accountAllCombo.filter(acc => suggestDrvIds.includes(acc.id))
    console.log("suggestAccCombo", top)
    const other = msData.accountAllCombo.filter(acc => !suggestDrvIds.includes(acc.id))
    console.log("suggestAccCombo", other)
    return [
      ...top.map(acc => ({ ...acc, group: "แนะนำ" }))
      , ...other
    ]
  }, [msData.accountAllCombo, suggestDrvIds])

  const textFieldProps = useCallback((name, isRequired) => ({
    fullWidth: true,
    required: isRequired,
    label: pettyCashColNm[name],
    size: "small",
    error: isRequired && !dialogData[name],
    value: dialogData[name] ?? "",
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value })),
  }), [dialogData, setDialogData])

  const comboboxProps = useCallback((nameId, nameNm, options) => ({
    label: pettyCashColNm[nameId],
    sx: { width: "100%" },
    options: options,
    selectedId: dialogData[nameId],
    setSelectedId: (id) => setDialogData(o => ({ ...o, [nameId]: id, [nameNm]: options.find(option => option.id === id)?.label })),
  }), [dialogData, setDialogData])

  const dateTimePickerProp = useCallback((name) => ({
    label: shipmentColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} fullWidth />,
  }), [dialogData])

  const setUntPrByJobOrdIdExpId = useCallback((jobOrdId, expId) => {
    console.log("setUntPrByJobOrdIdExpId", jobOrdId, expId)
    if (isPreventUpdateAmnt) return

    if (dialogData.PCPrdId) return

    if (expId) {

      setIsLoadingUntPr(true)
      ax.post(expensePOIApiNm.getUntPrByJobOrdIdExpId, { JobOrdId: jobOrdId || null, ExpId: expId }, false).then(value => {
        setIsLoadingUntPr(false)
        if (value.data && value.data.length === 1) {
          setDialogData(o => ({
            ...o,
            Amnt: value.data[0].UntPr,
          }))
        } else {
          setDialogData(o => ({
            ...o,
            Amnt: "",
          }))
        }
      })
    }
  }, [ax, setDialogData, dialogData.PCPrdId])

  const handleExpChange = useCallback((id) => {
    console.log("handleExpChange", id)
    setDialogData(o => {
      setUntPrByJobOrdIdExpId(o.JobOrdId, id)
      return { ...o, ExpId: id }
    })
  }, [setDialogData, setUntPrByJobOrdIdExpId])

  const handleSaveExpensePOI = useCallback(async () => {
    initExpensePOIData = {
      ExpPOIId: null,
      IsCost: 1,
      ExpId: dialogData.ExpId,
      VatPrct: 0,
      WhtPrct: 0,
      UntPr: dialogData.Amnt,
      IsOwnRcpt: 1,
      Prty: 50,
      JobId: dataRow.JobId,
      CusId: null,
      POIId: null,
      ContSize: "",
      ContTyp: "",
      ShpmTypId: null,
      TlrTypId: null,
      Rmk: ""
    }

    if (dialogData.JobOrdId) {
      const postData = {
        JobOrdId: dialogData.JobOrdId
      }
      const res = await ax.post(jobOrderApiNm.getJobOrder, postData)
      if (res.data && res.data.length > 0) {
        const data = res.data[0]
        initExpensePOIData = {
          ...initExpensePOIData,
          JobId: data.Shipment.JobId,
          CusId: data.Shipment.CusId,
          POIId: data.LocPOIId,
          ContSize: data.ContSize,
          ContTyp: data.ContTyp,
          ShpmTypId: data.ShpmTypId,
          TlrTypId: data.TlrTypId,
        }
      }
    }

    setDialogExpensePOIOpen(true)
  }, [dialogData, dataRow, ax])

  const onExpensePOIFinish = useCallback((data) => {
    if (data) {
      alertSuccess("บันทึกข้อมูลเรียบร้อยแล้ว")
    }
  }, [])

  const handleSave = useCallback(() => {
    if (!dialogData.Amnt) {
      alertError("กรุณาระบุจำนวนเงิน")
      return
    }
    if (!dialogData.UsrAccId) {
      alertError("กรุณาระบุผู้รับเงิน")
      return
    }
    if (!dialogData.PayTm) {
      alertError("กรุณาระบุเวลา")
      return
    }

    const postdata = {
      ExpId: dialogData.ExpId,
      DrvId: dialogData.UsrAccId,
      JobOrdId: dataRow.JobOrdId,
      PayTm: dayjs(dialogData.PayTm).format("YYYY-MM-DD HH:mm"),
      Amnt: dialogData.Amnt,
      Dscp: dialogData.Dscp,
      SalDte: dataRow.SalDte ? dayjs(dataRow.SalDte).format("YYYY-MM-DD") : null,
      getArgs: lastFilter
    }
    if (pcItmId) {
      postdata.PCTrnsId = dataRow.PCTrnsId
      postdata.PCItmId = dataRow.PCItmId
    }
    ax.post(pcItmId ? branchApiNm.updateDriverPaymentAmnt : branchApiNm.insertDriverPayment, postdata).then(value => {
      if (value.data) {
        onFinish(value.data)
        setDialogOpen(false)
      }
    })

  }, [dialogData, ax, onFinish, dataRow, lastFilter, setDialogOpen, pcItmId])

  const handleDelete = useCallback(() => {
    alertConfirmDelete(() => {
      const postData = { PCTrnsIds: [dataRow.PCTrnsId], getArgs: lastFilter }
      ax.post(branchApiNm.deleteDriverPayment, postData).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    })
  }, [ax, dataRow.PCTrnsId, lastFilter, onFinish, setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      isPreventUpdateAmnt = false
      if (!pcItmId) {
        setDialogData({ ...initAddDriverTripDialogData, PayTm: dayjs() })
      } else {
        setDialogData({
          ...initAddDriverTripDialogData,
          ...dataRow,
        })
      }
    } else {
      setDialogData({ ...initAddDriverTripDialogData })
    }
  }, [dialogOpen, ax, pcItmId, oData, dataRow])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`${pcItmId ? "แก้ไข" : "เพิ่ม"}ค่าเที่ยว พขร.`}
        onDeleteClick={pcItmId ? handleDelete : null}
      />
      <DialogContent>
        <BoxFC pt={2} sx={{ gap: 1 }} >
          <Typography variant="body" ><strong >ใบงาน:</strong> {dataRow.JobOrdId}</Typography>
          <Typography variant="body" ><strong >งาน:</strong> {dataRow.JobSNm}</Typography>
          <Typography variant="body" ><strong >สถานที่:</strong> {`${dataRow.TakePlc} - ${dataRow.Loc} - ${dataRow.RtnPlc}`}</Typography>
          <BoxFR>
            <ComboBox sx={{ flex: 1 }} {...comboboxProps("UsrAccId", "UsrNNm", suggestAccCombo)}
              required={true}
              selectedId={dialogData.UsrAccId} />
            <DateTimePickerCustomTime  {...dateTimePickerProp("PayTm", true)} />
          </BoxFR>
          <BoxFR>
            <ComboBox {...comboboxProps("ExpId", "ExpNm", tripExps)}
              setSelectedId={handleExpChange}
              required={true} />
            <TextField {...textFieldProps("Amnt", true)} type="number" disabled={isLoadingUntPr || dialogData.PCPrdId ? true : false}
              onFocus={selectOnFocus}
              inputProps={{ style: { textAlign: "right" } }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    {isLoadingUntPr ? <CircularProgress size={20} /> : "บาท"}
                  </InputAdornment>
              }} />
          </BoxFR>


          <TextField {...textFieldProps("Dscp")} />
          <Button size="small" sx={{ width: 150 }} onClick={handleSaveExpensePOI}><SaveRounded sx={{ mr: 1 }} /> บันทึกข้อมูลใน 4.3</Button>
        </BoxFC>
        <ExpensePOIDialog
          dialogOpen={dialogExpensePOIOpen}
          setDialogOpen={setDialogExpensePOIOpen}
          selectedId={null}
          lastFilter={null}
          onFinish={onExpensePOIFinish}
          initData={initExpensePOIData}
          suggessPOIIds={null}
        />
      </DialogContent>
      <DialogFooter okText={"บันทึกค่าเที่ยว พขร."}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(AddDriverTripDailog)