import { Paper,  Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import SelectJob from "pages/Menu6/ServerDataManagement/RefColumnNameSelectJob/SelectJob";
import React, { useCallback} from "react";
import { addNoDuplicate } from "utils";
import SaveDataButton from "./SaveDataButton";

const RefColumnJobContent = ({ refColumnJob, setRefColumnJob }) => {
  console.log({refColumnJob})

  const setSelectedJoblData = useCallback((name) => (jobData, isDelete) => {
    if(isDelete){
      setRefColumnJob(o => ({ ...o, [name]: o[name].filter(item=>item.id !== jobData.id)}))
    } else {
      setRefColumnJob(o => ({ ...o, [name]: addNoDuplicate(o[name], jobData, "id") }))
    }
  }, [setRefColumnJob])

  return (
    <BoxFC>
      <Typography variant="h6">Column เพิ่มเติม - งาน</Typography>
      <BoxFC >
        <BoxFR>
          <Paper elevation={4} sx={{ p: 2, flex:1 }}>
            <BoxFC height={500} >
              <Typography fontWeight="bold">การส่ง Email</Typography>
              <SelectJob sx={{ flex: 1 }}
                selectedJobData={refColumnJob.email}
                setSelectedJobData={setSelectedJoblData("email")} />
            </BoxFC>
          </Paper>
          <Paper elevation={4} sx={{ p: 2, flex:1  }}>
            <BoxFC height={500} >
              <Typography fontWeight="bold">การพิมพ์ Invoice</Typography>
              <SelectJob sx={{ flex: 1 }}
                selectedJobData={refColumnJob.invoice}
                setSelectedJobData={setSelectedJoblData("invoice")} />
            </BoxFC>
          </Paper>
        </BoxFR>
      </BoxFC>
      <SaveDataButton sx={{ width: 150 }} name="RefColumnJob" data={refColumnJob} />
    </BoxFC>
  )
}
export default React.memo(RefColumnJobContent)