import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import JobOrderNoDriver from "./JobOrderNoDriver";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { UserContext } from "contexts/UserContext";
import { checkAndAddInArray } from "./function";
import { branchApiNm } from "branch/constant/branchApiNm";
import AddDataDialog from "./AddDataDialog";
import DriverData from "./DriverData";
import { alertError } from "components/Alert";
import dayjs from "dayjs";
import { getDataAndPrintJobOrder } from "utils/getDataAndPrintjobOrder";

const initJobData = { nd: [], yd: [], td: [], tm: [], atm: [] }

let selectedRow = null

const JobPlaning = () => {

  const { ax, msData } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)

  const [jobData, setJobData] = useState({ ...initJobData })
  const [driverData, setDriverData] = useState([])
  const [driverWithBatt, setDirverWithBatt] = useState([])
  const [dialogAddDataOpen, setDialogAddDataOpen] = useState(false)
  const [selectionDrvs, setSelectionDrvs] = useState([])
  const [leftPanelWidth, setLeftPanelWidth] = useState(800)
  const [driverAttendData, setDriverAttendData] = useState([])
  const [curDate, setCurDate] = useState(dayjs())

  const setAllData = useCallback((data) => {

    setJobData(checkAndAddInArray(data.jobOrderNoDrivers))
    setDriverData(data.jobOrders)
    setDirverWithBatt(data.driverWithBatt)
    setDriverAttendData(data.driverAttend)
  }, [])

  const getData = useCallback(() => {
    const postData = {
      AptTmSt: curDate.add(-1, "day").format("YYYY-MM-DD 00:00:00"),
      AptTmEn: curDate.add(2, "day").format("YYYY-MM-DD 23:59:59")
    }
    ax.post(branchApiNm.getJobPlaning, postData).then(value => {
      if (value.data) {
        setAllData(value.data)
      }
    })
  }, [ax, setAllData, curDate])

  const handleSelect = useCallback((row) => {
    // if(selectionDrvs.length === 0){
    //   alertError("กรุณาเลือกพนักงานขับรถก่อน")
    //   return
    // }
    if (row.JobOrdIds.length < selectionDrvs.length) {
      alertError("จำนวนงานมีน้อยกว่าจำนวนพนักงานขับรถที่เลือก")
      return
    }
    selectedRow = row
    setDialogAddDataOpen(true)
  }, [selectionDrvs])

  const confirmSelect = useCallback(({ row, data, isPrint }) => {
    console.log("in confirmSelect row::", row)
    const postData = {}
    const now = dayjs().format("YYYY-MM-DD HH:mm:ss")

    const jobOrdArr = []
    const contStatusData = []
    let i = 0;
    const drvs = selectionDrvs.length === 0 ? [data.DrvId] : selectionDrvs
    for (const drvId of drvs) {
      const tukId = selectionDrvs.length === 0 ? data.TukId: msData.drivers.find(driver => driver.DrvId === drvId)?.TukId
      for (let j = 0; j < data.NumJobOrd; j++) {
        const jobOrdId = row.JobOrdIds[i]
        jobOrdArr.push({
          JobOrdId: jobOrdId,
          ColValues: {
            DrvId: drvId,
            TukId: tukId,
            DrvActTm: now,
            AptTm: data.AptTm.format("YYYY-MM-DD HH:mm:ss")
          },
          LogRmk: "แก้ไขเวลานัดและผู้รับผิดชอบงาน"
        })
        const contStsTemplate = { JobOrdId: jobOrdId, DrvId: drvId, TukId: tukId, StsTm: now }
        if (data.IsTake) {
          contStatusData.push({ ContStsId: 1, ...contStsTemplate })
        }
        if (data.IsLoc) {
          contStatusData.push({ ContStsId: 3, ...contStsTemplate })
        }
        if (data.IsRtn) {
          contStatusData.push({ ContStsId: 5, ...contStsTemplate })
        }
        i++
      }
    }

    postData.jobOrdArr = jobOrdArr
    postData.contStatusData = contStatusData
    postData.getArgs = {
      getFn: "getJobPlanning",
    }

    ax.post(branchApiNm.updateJobOrderAllDetail, postData).then(value => {
      if (value.data) {
        setAllData(value.data)
        if (isPrint) {
          getDataAndPrintJobOrder(ax, msData, jobOrdArr.map(jo => jo.JobOrdId), user.FName)
        }
      }
    })
  }, [ax, msData, setAllData, selectionDrvs, user.FName])

  const onFinish = useCallback((data) => {
    if (data) {
      setAllData(data)
    } else {
      getData()
    }
  }, [getData, setAllData])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <BoxFC px={2} height="100%">
      <BoxFR height="100%" sx={{ alignItems: "stretch" }}>
        <JobOrderNoDriver
          jobData={jobData}
          handleSelect={handleSelect}
          selectionDrvs={selectionDrvs}
          leftPanelWidth={leftPanelWidth} 
          confirmSelect={confirmSelect}/>
        <DriverData
          driverData={driverData}
          driverWithBatt={driverWithBatt}
          setDriverData={setDriverData}
          refreshData={getData}
          selectionDrvs={selectionDrvs}
          setSelectionDrvs={setSelectionDrvs}
          setLeftPanelWidth={setLeftPanelWidth}
          leftPanelWidth={leftPanelWidth}
          onFinish={onFinish} 
          driverAttendData={driverAttendData}
          curDate={curDate}
          setCurDate={setCurDate}/>
      </BoxFR>

      <AddDataDialog
        dialogOpen={dialogAddDataOpen}
        setDialogOpen={setDialogAddDataOpen}
        row={selectedRow}
        confirmSelect={confirmSelect}
        selectionDrvs={selectionDrvs} />

      {/* <JobWorkingTimeDialog
        dialogOpen={jobWorkingTimeDialogOpen}
        setDialogOpen={setJobWorkingTimeDialogOpen}
        onFinish={onFinish} /> */}
    </BoxFC>
  )
}

export default JobPlaning;