import { Paper, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback } from "react";
import { textFieldProp } from "./constant";
import SaveDataButton from "./SaveDataButton";

const RefColumnNameContent = ({ refColumnName, setRefColumnName }) => {


  const refColumnNameProps = useCallback((table, name) => ({
    ...textFieldProp,
    name: name,
    value: refColumnName[table][name],
    label: "ชื่อ Column",
    onChange: (e) => setRefColumnName(o => ({ ...o, [table]: { ...o[table], [name]: e.target.value } }))
  }), [refColumnName, setRefColumnName])
  return (
    <BoxFC>
      <Typography variant="h6">ชื่อ Column เพิ่มเติม</Typography>
      <BoxFC pl={2} >
        <BoxFR>
          <Paper elevation={4} sx={{ p: 2 }}>
            <BoxFC>
              <Typography fontWeight="bold">ใบแจ้งลาก</Typography>
              <BoxFR sx={{ gap: 2, pl: 2 }}>
                <Typography>เพิ่มเติม1</Typography>
                <TextField {...refColumnNameProps("shipment", "Ref1")} />
              </BoxFR>
              <BoxFR sx={{ gap: 2, pl: 2 }}>
                <Typography>เพิ่มเติม2</Typography>
                <TextField {...refColumnNameProps("shipment", "Ref2")} />
              </BoxFR>
            </BoxFC>
          </Paper>
        </BoxFR>
        <BoxFR>
          <Paper elevation={4} sx={{ p: 2 }}>
            <BoxFC>
              <Typography fontWeight="bold">ใบงาน</Typography>
              <BoxFR sx={{ gap: 2, pl: 2 }}>
                <Typography>เพิ่มเติม1</Typography>
                <TextField {...refColumnNameProps("job_order", "Ref1")} />
              </BoxFR>
              <BoxFR sx={{ gap: 2, pl: 2 }}>
                <Typography>เพิ่มเติม2</Typography>
                <TextField {...refColumnNameProps("job_order", "Ref2")} />
                {/* <CheckboxFormControl label="ส่ง Email"/>
                <CheckboxFormControl label="พิมพ์ใบแจ้งหนี้"/> */}
              </BoxFR>
            </BoxFC>
          </Paper>
        </BoxFR>
      </BoxFC>
      <SaveDataButton sx={{ width: 150 }} name="RefColumnName" data={refColumnName} />
    </BoxFC>
  )
}

export default React.memo(RefColumnNameContent)