import {  TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useEffect } from "react";
import {textFieldProp} from "./constant"
import SaveDataButton from "./SaveDataButton";

// const initCompanyData = {
//   NameTH: "บริษัท คณินทรานสปอร์ต จำกัด",
//   NameEN: "Kanin Transport Co., Ltd.",
//   AddrTH: "41/21 หมู่10 ต.ทุ่งสุขลา อ.ศรีราชา จ.ชลบุรี 20230",
//   AddrEN: "41/21 Moo 10 Thungsukla Sriracha Chonburi 20230",
//   TaxId: "0205541000163",
//   Tel: "038-401234-5",
//   Fax: "038-400141",
//   Email: "cs@kanintransport.com"
// }
let firstLoad = true
const CompanyDataContent = ({companyData, setCompanyData}) =>{ 
  
  const companyDataProps = useCallback((name) => ({
    ...textFieldProp,
    name: name,
    value: companyData[name],
    onChange: (e) => setCompanyData(o => ({ ...o, [name]: e.target.value }))
  }), [companyData, setCompanyData])

  useEffect(()=>{
    if(firstLoad) {
      console.log("first load")
      firstLoad = false
    } else{
      console.log("second load")
    } 
    //setHasChagne(true)
  }, [companyData])

  console.log("company Data::", companyData)
  return (
    <BoxFC>
      <Typography variant="h6">ข้อมูลบริษัท</Typography>
      <BoxFC pl={2} width={500}>
        {/* <Avatar sx={{width: 150, height:150, alignSelf:"center"}} /> */}
      
        <TextField {...companyDataProps("NameTH")} label="ชื่อบริษัท" />
        <TextField {...companyDataProps("NameEN")} label="ชื่อบริษัท(Eng)" />
        <TextField {...companyDataProps("Abbr")} label="ตัวย่อบริษัท" disabled/>
        <TextField {...companyDataProps("AddrTH")} label="ที่อยู่" multiline rows={3} />
        <TextField {...companyDataProps("AddrEN")} label="ที่อยู่(Eng)" multiline rows={3} />
        <TextField {...companyDataProps("TaxId")} label="เลขประจำตัวผู้เสียภาษี" />
        <TextField {...companyDataProps("Tel")} label="โทร." />
        <TextField {...companyDataProps("Fax")} label="แฟกซ์" />
        <TextField {...companyDataProps("Email")} label="อีเมล" />
        <BoxFR>
          <Typography sx={{width: 100}}>พิกัด บริษัท</Typography>
        <TextField {...companyDataProps("Lat")} label="Latitude" type="number" />
        <TextField {...companyDataProps("Lng")} label="longitude" type="number" />
        </BoxFR>
      </BoxFC>
      <SaveDataButton sx={{width: 150}} name="CompanyData" data={companyData} />
    </BoxFC>
  )
}

export default React.memo(CompanyDataContent)