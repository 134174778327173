import { Button, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useContext, useMemo, useState } from "react";
import SaveDataButton from "./SaveDataButton";
import { driverColNm } from "constants/driverConstant";
import { blue, grey, purple } from "@mui/material/colors";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { alertError } from "components/Alert";

export const drvItems = ["FName", "LName", 'FNmSLNm', "NName", "LicTyp", "GrpNm"]
export const truckItems = ["Code", "LicNm"]
export const initCharItems = ["(", ")", "[", "]", "-", "_", "#", "|", "@", " "]
const initObjectItems = [...drvItems, ...truckItems]

const ObjButton = ({ value, onClick, isUp }) => {
  const handleClick = useCallback(() => {
    onClick(isUp)
  }, [isUp, onClick])
  return <Button variant="contained" color="primary" onClick={handleClick} sx={{ minWidth: 0, px: 1 }}>
    {driverColNm[value]}
  </Button>
}

const CharButton = ({ value, onClick, isUp }) => {
  const handleClick = useCallback(() => {
    onClick(isUp)
  }, [isUp, onClick])
  return <Button variant="contained" color="secondary" onClick={handleClick} sx={{ minWidth: 0, px: 1 }}>
    {value}
  </Button>
}
const DriverCombo = ({ driverCombo, setDriverCombo }) => {

  const { msData } = useContext(GlobalStateContext)

  const [objectItems, setObjectItems] = useState([...initObjectItems])
  // const [resultObject, setResultObject] = useState([])

  const resultObject = useMemo(()=>driverCombo? driverCombo.split(","): [], [driverCombo])
  const resultExample = useMemo(() => {
    // for(const item of resultObject){
    //   if(typeof item.props.value === "string"){
    //     const index = resultObject.indexOf(item)
    //     resultObject[index] = item.props.value
    //   }
    // }
    const drvEx = msData.drivers.filter(d=>d.IsSub===0 && d.IsActv === 1)[0]
    if (!drvEx) return "กรุณาเพิ่มพนักงานขับรถใน 2.3 ก่อน"

    const tukEx = msData.truckObj[drvEx.TukId]

    let result = ""
    for (const item of resultObject) {
      if (initCharItems.includes(item)) {
        result += item
      } else {
        if (drvItems.includes(item)) {
          result += drvEx[item]
        } else {
          result += tukEx?.[item] || ""
        }
      }
    }
    return result
  }, [resultObject, msData.drivers, msData.truckObj])

  const addObj = useCallback((item) => (isUp) => {
    if (isUp) {
      setObjectItems(o => o.filter(i => i !== item))
      setDriverCombo([...resultObject, item].join(","))
      // setResultObject(o => [...o, item])
      // setResultObject(o => [...o, <ObjButton key={item} value={item} onClick={addObj(item)} isUp={false}/>])
    } else {
      setDriverCombo(resultObject.filter(i => i !== item).join(","))
      // setResultObject(o => o.filter(i => i !== item))
      setObjectItems(o => {
        const result = [...o, item]
        return initObjectItems.filter(i => result.includes(i))
      })
    }
  }, [setDriverCombo, resultObject])

  const addChar = useCallback((item) => (isUp) => {
    if (isUp) {
      setDriverCombo([...resultObject, item].join(","))
      // setResultObject(o => [...o, item])
    } else {
      setDriverCombo(resultObject.filter(i => i !== item).join(","))
      // setResultObject(o => o.filter(i => i !== item))
    }
  }, [setDriverCombo, resultObject])

  const checkDataB4Save = useCallback(() => {
    if(resultObject.length === 0 || !resultObject.some(item=>initObjectItems.includes(item))){
      alertError("ผลลัพธ์ต้องมีรายการตัวเลือกอย่างน้อย 1 อย่าง")
      return false
    }
    return true
  }, [resultObject])
  // useEffect(()=>{
  //   console.log("driverCombo:::", driverCombo)
  //   setResultObject(driverCombo? driverCombo.split(","): [])
  // }, [driverCombo])

  console.log("driverCombo:::", driverCombo)

  return (
    <BoxFC>
      <Typography variant="h6">ต้งค่า Combobox พนักงานขับรถ</Typography>

      <BoxFC pl={2} width={670} sx={{ gap: 0 }}>
        <Typography fontWeight="bold">รายการ ตัวเลือก</Typography>
        <BoxFR borderRadius={2} bgcolor={blue[100]} p={1} height={53}>
          {objectItems.map(item => <ObjButton key={item} value={item} onClick={addObj(item)} isUp={true} />)}
        </BoxFR>
        <Typography mt={1} fontWeight="bold">รายการ เครื่องหมายวรรคตอน</Typography>
        <BoxFR borderRadius={2} bgcolor={purple[100]} p={1} height={53}>
          {initCharItems.map(item => <CharButton key={item} value={item} onClick={addChar(item)} isUp={true} />)}
        </BoxFR>
        <Typography mt={1} fontWeight="bold">ผลลัพธ์</Typography>
        <BoxFR borderRadius={2} bgcolor={grey[200]} p={1} height={53}>
          {resultObject.map(item => initCharItems.includes(item) ?
            <CharButton key={item} value={item} onClick={addChar(item)} isUp={false} /> :
            <ObjButton key={item} value={item} onClick={addObj(item)} isUp={false} />
          )}
        </BoxFR>
        <TextField sx={{ mt: 2 }} size="small" fullWidth label="ตัวอย่าง" value={resultExample} />
      </BoxFC>
      <SaveDataButton 
        sx={{ width: 150 }} 
        name="DriverCombo" 
        data={resultObject.length>0?resultObject.join(","): ""} 
        checkData={checkDataB4Save}/>
    </BoxFC>
  )
}

export default React.memo(DriverCombo)