import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { GridActionsCellItem } from '@mui/x-data-grid';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

export const columns = (handleDeleteRow, refColumnName) => [
  {
    field: 'No',
    width: 40,
    align: 'center',
  },
  {
    field: 'label',
    headerName: "ชื่องาน",
    flex: 1,
  },
  {
    field: 'shipment_Ref1',
    headerName: `${refColumnName.shipment.Ref1}(S)`,
    width: 110,
    type: 'boolean',
    renderCell: (params) => {
      return params.value && <CheckRoundedIcon />
    },
  },
  {
    field: 'shipment_Ref2',
    headerName: `${refColumnName.shipment.Ref2}(S)`,
    width: 110,
    type: 'boolean',
    renderCell: (params) => {
      return params.value && <CheckRoundedIcon />
    },
  },
  {
    field: 'job_order_Ref1',
    headerName: `${refColumnName.job_order.Ref1}(J)`,
    width: 110,
    type: 'boolean',
    renderCell: (params) => {
      return params.value && <CheckRoundedIcon />
    },
  },
  {
    field: 'job_order_Ref2',
    headerName: `${refColumnName.job_order.Ref2}(J)`,
    width: 110,
    type: 'boolean',
    renderCell: (params) => {
      return params.value && <CheckRoundedIcon />
    },
  },
  {
    field: "actions",
    type: "actions",
    width: 20,
    minWidth: 20,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<DeleteForeverIcon />}
        label="Delete"
        onClick={handleDeleteRow(params.row.id)}
      />
    ] ,
  }
]
