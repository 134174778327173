import { ArticleRounded } from "@mui/icons-material";
import { IconButton} from "@mui/material";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { shipmentColNm } from "constants/shipmentConstant";
import dayjs from "dayjs";
import { numberFormat } from "utils";


export const addExpenseJobOrderMTBColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "CusSNm",
    headerName: jobOrderColNm.CusSNm,
    width: 120,
  },
  {
    field: "JobSNm",
    headerName: jobOrderColNm.JobSNm,
    width: 120,
  },
  {
    field: "ShpmTypNm",
    headerName: shipmentColNm.ShpmTypNm,
    width: 100,
  },
  {
    field: "AptTm",
    headerName: jobOrderColNm.AptTm,
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "FinDte",
    headerName: jobOrderColNm.FinDte,
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 100,
  },
  {
    field: "DrvSts3NNm",
    headerName: jobOrderColNm.DrvNm,
    width: 150,
  },
  {
    field: "TukSts3LicNm",
    headerName: jobOrderColNm.LicNm,
    width: 100,
  },
  {
    field: "Loc",
    headerName: jobOrderColNm.Loc,
    width: 300,
    valueGetter: ({ row }) => `${row.TakePlc}-${row.Loc}-${row.RtnPlc}`,
  },
  {
    field: "Ref3",
    headerName: "เลขงานอ้างอิง",
    width: 140,
    editable: true,
  },
  {
    field: "TareWt",
    headerName: "Dimension",
    width: 100,
    editable: true,
    type: "number",
  },
  {
    field: "FullWt",
    headerName: "Weight",
    width: 100,
    editable: true,
    type: "number",
  },
  {
    field: "E1",
    headerName: "ค่าขนส่ง",
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: (params) => params.value != null ? numberFormat(params.value) : "-",
    width: 100,
  },
  // {
  //   field: "OthAmnt",
  //   headerName: "ค่าอื่นๆ",
  //   align: "right",
  //   valueFormatter: (params) => params.value != null  ? numberFormat(params.value) : "-",
  //   width: 120
  // },
  // {
  //   field: "Total",
  //   headerName: "Total",
  //   align: "right",
  //   valueFormatter: (params) => params.value != null  ? numberFormat(params.value) : "-",
  //   width: 100,
  // },
  // {
  //   field: "InvDte",
  //   headerName: "วันที่ใบแจ้งหนี้",
  //   width: 100,
  //   type: "dateTime",
  //   valueFormatter: (params) => {
  //     return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
  //   },
  // },
  // {
  //   field: "OthExp",
  //   headerName: "รายละเอียดค่าอื่นๆ",
  //   width: 200,
  // },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "InvNo",
    headerName: "เลขที่ใบแจ้งหนี้",
    width: 120,
  },
]


export const dhlColumns = [
  
  {
    field: "Transport",
    headerName: "Transport/Depot",
    width: 80,
    valueGetter: (()=>"Transport")
  },
  {
    field: "AptTm",
    headerName: "วันที่(DATE)",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  {
    field: "JobSNm",
    headerName: "DEPOT",
    width: 100,
  },
  {
    field: "TruckType",
    headerName: "ประเภทรถ",
    width: 80,
    valueGetter: (()=>"6W")
  },
  {
    field: "Ref3",
    headerName: "TOUR ID/TRIP ID",
    width: 130,
  },
  {
    field: "FullWt",
    headerName: "จำนวน NET WEIGHT",
    width: 130,
  },
  {
    field: "TareWt",
    headerName: "จำนวน CULBIC METERS",
    width: 130,
  },
  {
    field: "E1",
    headerName: "จำนวนเงิน(THB)",
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: (params) => params.value != null ? numberFormat(params.value) : "-",
    width: 100,
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ (Remark)",
    flex: 1,
    minWidth: 120,
  },
]