import { Box, Dialog, DialogContent} from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import React from "react"
import { dhlColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"

const DHLTableDialog = ({ datatable, dialogOpen, setDialogOpen }) => {

  
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายงานการวิ่งาน DHL"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <Box height={600}>
            <DataGridCellExpand
              hideFooter
              rows={datatable}
              columns={dhlColumns}
            />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(DHLTableDialog)