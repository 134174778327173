export const salaryDriverColNm = {
  TripAmnt: "ค่าเที่ยว",
  SalAmnt: "เงินเดือน",
  IncAmnt1: "ค่าวิชาชีพ",
  IncAmnt2: "ค่าโทรศัพท์",
  IncAmnt3: "ค่าขับขี่ปลอดภัย",
  IncAmnt4: "เบี้ยขยัน",
  IncAmnt5: "ค่าดูแลรักษารถ",
  IncAmnt6: "รายได้6",
  IncAmnt7: "รายได้7",
  OthAmnt: "รายได้อื่นๆ",
  OthDscp: "รายละเอียดรายได้อื่นๆ",
  DeSSPrct: "ปกส.(%)",
  DeSSAmnt: "หัก ปกส.",
  DeSecDepAmnt: "หักเงินประกันรถ",
  DeTfcFineAmnt: "หักค่าปรับ",
  DeAmnt1: "หักขาดงาน",
  DeAmnt2: "หักลา",
  DeOthAmnt: "หักอื่นๆ",
  DeOthDscp: "รายละเอียดหักอื่นๆ",
  TotalInc: "รวมรายได้",
  TotalDedc: "รวมรายการหัก",
  LeftAmnt: "เงินขาด-เกิน",
  GrndTotal: "คงเหลือ",
  SalDte: "รอบคิดเงินเดือน",
  DrvAdvPay: "เงินเบิกล่วงหน้า",
}