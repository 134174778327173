import { jsPDF } from "jspdf";
// import { toBahtText } from "utils/toBahtText";
import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"
import dayjs from "dayjs";
import { blue } from "@mui/material/colors";
import { printHeader } from "branch/functions/pirntHeader";


export const printSummaryByCus = ({  dataTable, msData }) => {
  const companyData = msData.serverData.CompanyData
  const startY = 0.3
  const startX = 0.5
  const pageSize = [8.3, 11.7];
  const lineHeight = 0.19
  const contentSize = 14;
  let yPos = startY
  let xPos = startX
  const doc = new jsPDF({
    orientation: "potrait",
    unit: "in",
    format: pageSize
  });
  doc.setLineWidth(0.01)
  doc.setFont("THSarabun")

  let pageNo = 1
  // let maxRow = MAX_PAGE_SIZE

  doc.setFont("THSarabun")
  if (pageNo !== 1) {
    doc.addPage(pageSize, "portrait")
  }
  xPos = startX
  yPos = startY
  yPos = printHeader({ jsPDFDoc: doc, companyData, startX: xPos - 0.2, startY: yPos })

  //---------------------------------draw top right -------------------------------------
  yPos = startY + 0.2
  doc.setFont("THSarabun", "bold")
  doc.setFontSize(20)
  doc.text("สรุปการส่งเอกสาร", 7, yPos, { align: "center" })

  doc.setFont("THSarabun", "bold")
  doc.setFontSize(contentSize)
  const lineHeightTopRight = lineHeight + lineHeight / 5
  const yPosTopRight = yPos + lineHeightTopRight
  const xPosTopRight = 6
  xPos = xPosTopRight
  yPos = yPosTopRight

  doc.text("ลูกค้า : ", xPos, yPos)
  yPos += lineHeightTopRight
  doc.text("รอบวันที่ : ", xPos, yPos)

  doc.setFont("THSarabun", "normal")
  yPos = yPosTopRight
  xPos += 0.8
  doc.text(dataTable[0]?.CusSNm, xPos, yPos)
  yPos += lineHeightTopRight
  doc.text(dayjs().format("D MMMM YYYY"), xPos, yPos)
  yPos += lineHeightTopRight


  const startTableDetailX = startX 
  xPos = startTableDetailX
  //-------------------------------------- Box Header --------------------------------------------------------
  const colNoW = 0.4
  const colDateW = 0.8
  const colJobNmW = 1.3
  const colDrvNmW = 1.5
  const colLocW = 1.5
  const colRefW = 1.7
  const dataBoxW = colNoW + colDateW + colJobNmW + colDrvNmW + colLocW + colRefW

  yPos += lineHeight - 0.1
  doc.setFillColor(blue[100])

  doc.rect(xPos, yPos, dataBoxW, lineHeight + 0.1, "F")
  doc.line(xPos, yPos, xPos + dataBoxW, yPos)

  //------------------------------------- Text Header ----------------------------------------------------------
  

  yPos += lineHeight
  xPos = startTableDetailX
  const drawHeader = (label, width) => {
    doc.text(label, xPos + width / 2, yPos, { align: "center" })
    xPos += width
  }
  const drawText = (text, width) => {
    doc.text(text, xPos + 0.1, yPos,)
    xPos += width
  }
  drawHeader("ลำดับ", colNoW)
  drawHeader("วันที่", colDateW)
  drawHeader("ชื่องาน", colJobNmW)
  drawHeader("พนักงานขับรถ", colDrvNmW)
  drawHeader("สถานที่", colLocW)
  drawHeader("เลขที่อ้างอิง", colRefW)
  yPos += 0.1
  doc.line(startTableDetailX, yPos, startTableDetailX + dataBoxW, yPos)

  //------------------------------------- Data ----------------------------------------------------------

  doc.setFont("THSarabun", "normal")
  yPos += lineHeight
  xPos = startTableDetailX
  doc.setFontSize(contentSize)
  let no = 0
  for (const data of dataTable) {
    no++
    xPos = startTableDetailX
    drawHeader(`${no}`, colNoW)
    drawText(`${dayjs(data.AptTm).format("DD/MM/YYYY")}`, colDateW)
    drawText(`${data.JobSNm}`, colJobNmW)
    drawText(`${data.Sts3NNm}`, colDrvNmW)
    drawText(`${data.Loc}`, colLocW)
    drawText(`${data.Ref3 || ""}`, colRefW)
    yPos += lineHeight
  }
  doc.setFontSize(contentSize )
  doc.line(startTableDetailX, yPos - 0.1, startTableDetailX + dataBoxW, yPos - 0.1)

  yPos += (lineHeight * 2) + 0.1

  doc.setFont("THSarabun", "normal")
  const signWidth = 1.5
  const startsignX = startTableDetailX + 0.4
  const col1 = startsignX
  const col2 = startsignX +4.4

  doc.line(col1, yPos, col1 + signWidth, yPos)
  doc.line(col2, yPos, col2 + signWidth, yPos)
  yPos += lineHeight
  doc.text("ผู้จัดทำ", col1 + signWidth / 2, yPos, { align: "center" })
  doc.text("ผู้รับเอกสาร", col2 + signWidth / 2, yPos, { align: "center" })

  doc.output('dataurlnewwindow');
}