import dayjs from "dayjs"

export const clearFilterData = {
  AptTmSt: null,
  AptTmEn: null,
  FinDteSt: null,
  FinDteEn: null,
  DrvId: null,
  SalDte: null,
  JobOrdId: null,
  ContNo: null,
  JobId: null,
  CusId: null,
  ShpmTypId: null,
  IsPaySts: null,
  IsFilterSalDte: 0,
}

export const initFilterData = {
  ...clearFilterData,
  FinDteSt: dayjs().startOf("day"),
  FinDteEn: dayjs().endOf("day"),
}


export const initAddDriverTripDialogData = {
  PayTm: "",
  UsrAccId: "",
  JobOrdId: null,
  Loc: "",
  ExpId: null,
  Amnt: 0,
  Dscp: "",
  Rmk: "",
}