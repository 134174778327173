import { Divider, List, ListItem, ListItemButton, ListItemText, Paper } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box } from "@mui/system";
import { BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext, useState } from "react";
import CompanyDataContent from "./CompanyDataContent";
import SendEmailContent from "./SendEmailContent";
import PreTextContent from "./PreTextContent";
import { initCompanyData, initSendEmailData, initPreText, initRefColumnName, initRefColumnJob } from "./constant";
import { alertConfirm } from "components/Alert";
import ContainerStatusContent from "./ContainerStatusContent";
import RefColumnNameContent from "./RefColumnNameContent";
import RefColumnJobContent from "./RefColumnJobContent";
// import ShorePriceContent from "./ShorePriceContent";
// import DefaultValueContent from "./DefaultValueContent";
import Feature from "./Feature";
import DriverCombo from "./DriverCombo";

// const initShorePrice = { "20": 0, "40": 0, "45": 0 }

// const initDefaultValue = { IsCash: 0, IsPaid: 0 }

const initFeature = { JobOrderTransFerDriverIncome: 1 }
export const initDriverCombo = "FNmSLNm,(,LicNm,)"

// const menu = ["ข้อมูลบริษัท", "ข้อมูลการส่งอีเมล", "ตัวเลือกข้อความ", "ค่าชอเริ่มต้น", "ตัวเลือกเริ่มต้น", "สถานะตู้", "ชื่อ Column เพิ่มเติม"
// , "Column เพิ่มเติม - งาน", "ตัวเลือก Features"]

const menu = ["ข้อมูลบริษัท", "ข้อมูลการส่งอีเมล", "ตัวเลือกข้อความ", "สถานะตู้", "ชื่อ Column เพิ่มเติม"
  , "Column เพิ่มเติม - งาน", "ตัวเลือก Features", "ต้งค่า Combobox พนักงานขับรถ"]

let lastSelectedIndex = 0;
const ServerDataManagement = () => {

  const { msData } = useContext(GlobalStateContext)
  const serverData = msData.serverData
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)

  const [companyData, setCompanyData] = useState({ ...(serverData.CompanyData || initCompanyData) })
  const [sendEmailData, setSendEmailData] = useState({ ...(serverData.SendEmailData || initSendEmailData) })
  const [preText, setPreText] = useState({ ...(serverData.PreText || initPreText) })
  // const [shorePrice, setShorePrice] = useState({ ...(serverData.ShorePrice || initShorePrice) })
  // const [defaultValue, setDefaultValue] = useState({ ...(serverData.DefaultValue || initDefaultValue) })
  const [refColumnName, setRefColumnName] = useState({ ...(serverData.RefColumnName || initRefColumnName) })
  const [refColumnJob, setRefColumnJob] = useState({ ...(serverData.RefColumnJob || initRefColumnJob) })
  const [contStses, setContStses] = useState(msData.containerStatuses.map(item => ({ ...item })))
  const [feature, setFeature] = useState({ ...(serverData.Feature || initFeature) })
  const [driverCombo, setDriverCombo] = useState(serverData.DriverCombo || initDriverCombo)

  const generateContent = useCallback(() => {
    if (selectedMenuIndex === 0) {
      return <CompanyDataContent companyData={companyData} setCompanyData={setCompanyData} />
    } else if (selectedMenuIndex === 1) {
      return <SendEmailContent sendEmailData={sendEmailData} setSendEmailData={setSendEmailData} />
    } else if (selectedMenuIndex === 2) {
      return <PreTextContent preText={preText} setPreText={setPreText} />
      // } else if (selectedMenuIndex === 3) {
      //   return <ShorePriceContent shorePrice={shorePrice} setShorePrice={setShorePrice} />
      // } else if (selectedMenuIndex === 4) {
      //   return <DefaultValueContent {...{defaultValue, setDefaultValue}} />
    } else if (selectedMenuIndex === 3) {
      return <ContainerStatusContent contStses={contStses} setContStses={setContStses} />
    } else if (selectedMenuIndex === 4) {
      return <RefColumnNameContent refColumnName={refColumnName} setRefColumnName={setRefColumnName} />
    } else if (selectedMenuIndex === 5) {
      return <RefColumnJobContent refColumnJob={refColumnJob} setRefColumnJob={setRefColumnJob} />
    } else if (selectedMenuIndex === 6) {
      return <Feature feature={feature} setFeature={setFeature} />
    } else if (selectedMenuIndex === 7) {
      return <DriverCombo driverCombo={driverCombo} setDriverCombo={setDriverCombo} />
    }
  }, [companyData, sendEmailData, preText, selectedMenuIndex, contStses, refColumnName, refColumnJob, feature, driverCombo])


  const confirmChangeMenu = useCallback((index, data, originalData, initData) => {
    // console.log("data::", data)
    // console.log("originalData::", originalData)
    // console.log("initData::", initData)
    if (JSON.stringify(data) !== JSON.stringify(originalData) &&
      JSON.stringify(data) !== JSON.stringify(initData)) {
      alertConfirm("ข้อมูลยังไม่ได้บันทึก ต้องการเปลี่ยนหัวข้อหรือไม่", () => {
        if (lastSelectedIndex === 0) setCompanyData(serverData.CompanyData)
        else if (lastSelectedIndex === 1) setSendEmailData(serverData.SendEmailData)
        else if (lastSelectedIndex === 2) setPreText(serverData.PreText)
        // else if (lastSelectedIndex === 3) setShorePrice(serverData.ShorePrice)
        // else if (lastSelectedIndex === 4) setDefaultValue(serverData.DefaultValue)
        else if (lastSelectedIndex === 3) setContStses(msData.containerStatuses.map(item => ({ ...item })))
        else if (lastSelectedIndex === 4) setRefColumnName(serverData.RefColumnName)
        else if (lastSelectedIndex === 5) setRefColumnJob(serverData.RefColumnJob)
        else if (lastSelectedIndex === 6) setFeature(serverData.Feature || initFeature)
        else if (lastSelectedIndex === 7) setDriverCombo(serverData.DriverCombo || "")
        lastSelectedIndex = index
        setSelectedMenuIndex(index)
      })
    } else {
      lastSelectedIndex = index
      setSelectedMenuIndex(index)
    }
  }, [serverData, msData.containerStatuses])

  const handleMenuClick = useCallback((index) => {
    console.log("index::", index, lastSelectedIndex)
    if (index === lastSelectedIndex) {
      return
    }
    if (lastSelectedIndex === 0) {
      confirmChangeMenu(index, companyData, serverData.CompanyData, initCompanyData)
    } else if (lastSelectedIndex === 1) {
      confirmChangeMenu(index, sendEmailData, serverData.SendEmailData, initSendEmailData)
    } else if (lastSelectedIndex === 2) {
      confirmChangeMenu(index, preText, serverData.PreText, initPreText)
      // } else if (lastSelectedIndex === 3) {
      //   confirmChangeMenu(index, shorePrice, serverData.ShorePrice, initShorePrice)
      // } else if (lastSelectedIndex === 4) {
      //   confirmChangeMenu(index, defaultValue, serverData.DefaultValue, initDefaultValue)
    } else if (lastSelectedIndex === 3) {
      confirmChangeMenu(index, contStses, msData.containerStatuses, msData.containerStatuses)
    } else if (lastSelectedIndex === 4) {
      confirmChangeMenu(index, refColumnName, serverData.RefColumnName, initRefColumnName)
    } else if (lastSelectedIndex === 5) {
      confirmChangeMenu(index, refColumnJob, serverData.RefColumnJob, initRefColumnJob)
    } else if (lastSelectedIndex === 6) {
      confirmChangeMenu(index, feature, serverData.Feature, initFeature)
    } else if (lastSelectedIndex === 7) {
      confirmChangeMenu(index, driverCombo, serverData.DriverCombo, "")
    }
  }, [companyData, sendEmailData, preText, serverData, contStses, refColumnName
    , refColumnJob, msData.containerStatuses, confirmChangeMenu, feature, driverCombo])

  return (
    <BoxFR p={1} sx={{ alignItems: "stretch", height: "100%" }}>
      <Paper sx={{ width: 400, height: "100%" }}>
        <List>
          {
            menu.map((item, index) => (
              <Box key={item}>
                <ListItem disablePadding sx={{ backgroundColor: selectedMenuIndex === index ? lightBlue[100] : "transparent" }}>
                  <ListItemButton onClick={() => handleMenuClick(index)}>
                    <ListItemText primary={item} />
                    <ArrowForwardIosRoundedIcon />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Box >
            ))
          }
        </List>
      </Paper>
      <Paper sx={{ p: 2, flex: 1, height: "100%" }}>
        {generateContent()}
      </Paper>
    </BoxFR>
  )
}

export default ServerDataManagement