import dayjs from "dayjs"
import { jsPDF } from "jspdf";

import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"

// import { branchNoText } from "utils/branchNoText";
import { printHeader } from "./pirntHeader";
// import { numberFormat } from "utils";
//{jobOrderData, shipmentData, jobOrdIds, fName, msData, isPrintDateTime}
export const printJobOrder = ({ printDataArr, msData }) => {

  console.log("printDataArr::", printDataArr)
  const companyData = msData.serverData.CompanyData

  const pageSize = [8.3, 11.7]; //A4
  const contentHeaderSize = 13;
  const contentSize = 18;

  const startY = 0.4
  const startX = 0.5
  const lineHeight = 0.25

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: pageSize
  });
  doc.setFont("THSarabun")

  let i = 0;
  for (const { jobOrderData, shipmentData, jobOrdIds, isPrintDateTime, fName } of printDataArr) {
    shipmentData.ShpmTypNm = msData.shipmentTypes.find(st => st.ShpmTypId === shipmentData.ShpmTypId)?.Name || ""
    jobOrderData.TakePlc = msData.poiObj[jobOrderData.TakePOIId] || ""
    jobOrderData.Loc = msData.poiObj[jobOrderData.LocPOIId] || ""
    jobOrderData.RtnPlc = msData.poiObj[jobOrderData.RtnPOIId] || ""
    let j = 0;
    do {
      if (i + j !== 0) {
        doc.addPage(pageSize, "portrait")
      }
      let yPos = startY
      let xPos = startX
      let startCopy = startY
      for (const copy of [1, 2]) {
        if (copy === 2) {
          startCopy = 5.85
          doc.setLineDashPattern([0.5, 3.2, 0.9, 3.2, 0.5])
          doc.line(0, startCopy, 8.3, startCopy)
          doc.setLineDashPattern()
          startCopy += 0.4
        }
        yPos = startCopy
        xPos = startX
        printHeader({ jsPDFDoc: doc, companyData, startX: xPos, startY: yPos })
        // const img = new Image()
        // img.src = companyLogo

        // try {
        //   doc.addImage(img, 'png', xPos, yPos -0.1, logoWidth, logoHeight)
        //   console.log("imgWidth::", img.width)
        //   xPos += logoWidth + 0.2
        // } catch (e) {
        //   console.log("error::", e)
        // }
        // doc.setFont("THSarabun", "bold")
        // doc.setFontSize(defaultHeaderSize)
        // doc.text(companyData.NameTH, xPos, yPos)
        // doc.setFont("THSarabun", "normal")
        // doc.setFontSize(comapanyAddrSize)
        // const lineHeightAddr = 0.18
        // yPos += lineHeightAddr
        // const addrs = doc.splitTextToSize(companyData.AddrTH, 4)
        // for(const addr of addrs){
        //   doc.text(addr, xPos, yPos)
        //   yPos += lineHeightAddr
        // }
        // // doc.text(companyData.AddrTH, xPos, yPos)
        // // yPos += lineHeight
        // doc.text(`เลขประจำตัวผู้เสียภาษี: ${companyData.TaxId}`, xPos, yPos)
        // yPos += lineHeightAddr
        // doc.text(`โทร: ${companyData.Tel}${companyData.Fax && ` Fax: ${companyData.Fax}`}`, xPos, yPos)

        yPos = startCopy
        xPos = 6.2
        const jobOrdTextX = 6.2
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentSize + 5)
        doc.text("ใบปฏิบัติงาน", jobOrdTextX, yPos, { align: "left" })

        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentSize - 3)
        doc.text(copy === 1 ? "(ต้นฉบับ)" : "(สำเนา)", jobOrdTextX + 1.15, yPos)
        const yPosTopRight = yPos + lineHeight + 0.05
        yPos = yPosTopRight
        doc.text("เลขที่ใบงาน: ", xPos, yPos)
        yPos += lineHeight //* 4/5
        doc.text("ประเภทงาน: ", xPos, yPos)
        yPos += lineHeight //* 4/5
        doc.text("วันที่: ", xPos, yPos)
        yPos = yPosTopRight
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize + 5)
        xPos += 0.8
        doc.text(jobOrdIds[j].toString(), xPos, yPos)
        yPos += lineHeight//* 4/5
        doc.setFontSize(contentSize)
        doc.text(shipmentData.ShpmTypNm, xPos, yPos)
        yPos += lineHeight//* 4/5

        if (jobOrderData.AptTm) {
          const aptTm = dayjs(jobOrderData.AptTm)
          let date = aptTm.format("DD/MM/YYYY")
          let time = aptTm.format("HH:mm")
          if (isPrintDateTime) {
            if (!isPrintDateTime.date) {
              date = ""
            }
            if (!isPrintDateTime.time) {
              time = ""
            }
          }
          if (time === "00:00") time = ""
          doc.text(`${date} ${time}`, xPos - 0.4, yPos)
        }


        const lineOffsetX = 0.05
        const lineOffsetY = 0.05
        const lineLenght = 2.5 - 0.7
        const lineLenght2x = 2.5 * 2 - 0.7
        const lineLenght3x = 2.5 * 3 - 0.7
        const yPosContent = yPos + 0.4
        const colHeader1 = startX
        const colText1 = colHeader1 + 0.7
        const lineText1 = colText1 - lineOffsetX
        const colHeader2 = colHeader1 + 2.5
        const colText2 = colHeader2 + 0.7
        const lineText2 = colText2 - lineOffsetX
        const colHeader3 = colHeader2 + 2.5
        const colText3 = colHeader3 + 0.7
        const lineText3 = colText3 - lineOffsetX
        const lineHeightContent =  lineHeight + lineHeight / 3

        doc.setLineWidth(0.007)

        yPos = yPosContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("ลูกค้า", colHeader1, yPos)
        doc.text("JOB No.", colHeader3, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        const shortCusNms = doc.splitTextToSize(shipmentData.CusNm, 4.3)
        doc.text(shortCusNms[0], colText1, yPos)
        doc.text(shipmentData.JobNo, colText3, yPos)
        doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght2x, yPos + lineOffsetY)
        doc.line(lineText3, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)

        yPos += lineHeightContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("รับสินค้า", colHeader1, yPos)
        doc.text("ส่งสินค้า", colHeader2, yPos)
        doc.text("คืนพาเลท", colHeader3, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(jobOrderData.TakePlc || "", colText1, yPos)
        doc.text(jobOrderData.Loc || "", colText2, yPos)
        doc.text(jobOrderData.RtnPlc || "", colText3, yPos)
        doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText2, yPos + lineOffsetY, lineText2 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText3, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)

        let contSizeFull = ""
        if (["20", "2x20", "40", "45"].includes(jobOrderData.ContSize)) {
          const contSize = jobOrderData.ContSize === "20x2" ? "20" : jobOrderData.ContSize
          if (jobOrderData.ContHq === 1 && jobOrderData.ContTyp === "GP") {
            contSizeFull = `${contSize}HQ`
          } else {
            contSizeFull = `${contSize}${jobOrderData.ContTyp}${jobOrderData.ContHq === 1 ? "HQ" : ""}`
          }
        } else {
          contSizeFull = jobOrderData.ContSize
        }
        if (jobOrderData.ContSize === "20") {
          contSizeFull = `1x${contSizeFull}`
        }
        // yPos += lineHeightContent
        // doc.setFont("THSarabun", "bold")
        // doc.setFontSize(contentHeaderSize)
        // doc.text("Booking", colHeader1, yPos)
        // doc.text("Agent", colHeader2, yPos)
        // doc.text("ขนาดตู้", colHeader3, yPos)
        // doc.setFont("THSarabun", "normal")
        // doc.setFontSize(contentSize)
        // doc.text(shipmentData.Bkg || "", colText1, yPos)
        // doc.text(shipmentData.Agnt || "", colText2, yPos)
        // doc.text(contSizeFull, colText3, yPos)
        // doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght, yPos + lineOffsetY)
        // doc.line(lineText2, yPos + lineOffsetY, lineText2 + lineLenght, yPos + lineOffsetY)
        // doc.line(lineText3, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)

        // yPos += lineHeightContent
        // doc.setFont("THSarabun", "bold")
        // doc.setFontSize(contentHeaderSize)
        // doc.text("หมายเลขตู้", colHeader1, yPos)
        // doc.text("หมายเลขซีล", colHeader3, yPos)
        // doc.setFont("THSarabun", "normal")
        // doc.setFontSize(contentSize)
        // doc.text(jobOrderData.ContNo, colText1, yPos)
        // doc.text(jobOrderData.SealNo, colText3, yPos)
        // doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght2x, yPos + lineOffsetY)
        // doc.line(lineText3, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)

        yPos += lineHeightContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("ทะเบียนหัว", colHeader1, yPos)
        doc.text("ทะเบียนหาง", colHeader2, yPos)
        doc.text("กำหนดงานเสร็จ", colHeader3, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(jobOrderData.RtnDteEn ? dayjs(jobOrderData.RtnDteEn).format("DD/MM/YYYY HH:mm") : "", colText3 + 0.2, yPos)
        doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText2, yPos + lineOffsetY, lineText2 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText3 + 0.2, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)

        yPos += lineHeightContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("หมายเหตุ", colHeader1, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(`${jobOrderData.Rmk} ${jobOrderData.Rmk && jobOrderData.Ref1 ? ", " : ""}${jobOrderData.Ref1}`, colText1, yPos)
        doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght3x, yPos + lineOffsetY)


        yPos += lineHeightContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("รายงานความผิดปกติ", colHeader1, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(`${jobOrderData.Rmk} ${jobOrderData.Rmk && jobOrderData.Ref1 ? ", " : ""}${jobOrderData.Ref1}`, colText1, yPos)
        doc.line(lineText1 + 0.4, yPos + lineOffsetY, lineText1 + lineLenght3x, yPos + lineOffsetY)
        doc.line(lineText1 + 0.4, yPos + lineOffsetY + lineHeightContent, lineText1 + lineLenght3x, yPos + lineOffsetY + lineHeightContent)

        yPos += lineHeightContent
        yPos += lineHeightContent

        const allBoxWidth = 7.8
        const startBoxX = startX - 0.2
        // if (copy === 1) {
        yPos -= 0.1
        const startBoxY = yPos
        const boxWidth = allBoxWidth / 4
        const col1 = startBoxX + 0.2
        const col2 = col1 + boxWidth
        const col3 = col2 + boxWidth
        const col4 = col3 + boxWidth
        const headerCol1 = col1 + (boxWidth / 2)
        const headerCol2 = col2 + (boxWidth / 2)
        const headerCol3 = col3 + (boxWidth / 2)
        const headerCol4 = col4 + (boxWidth / 2)

        const boxHeight = (lineHeightContent * 4)
        doc.lines([[allBoxWidth, 0], [0, boxHeight], [-allBoxWidth, 0]], startBoxX, startBoxY, [1, 1], 'S', true)
        yPos += 0.3
        doc.text("เริ่มงาน", headerCol1, yPos, { align: "center" })
        doc.text("รับสินค้า", headerCol2, yPos, { align: "center" })
        doc.text("ส่งสินค้า", headerCol3, yPos, { align: "center" })
        doc.text("คืนพาเลท", headerCol4, yPos, { align: "center" })
        const yCheckbox = yPos - 0.17
        const checkBoxWidth = 0.2
        const cornerRadius = 0.04
        // doc.roundedRect(headerCol1 -0.5, yCheckbox, checkBoxWidth, checkBoxWidth, cornerRadius, cornerRadius)
        doc.roundedRect(headerCol2 - 0.6, yCheckbox, checkBoxWidth, checkBoxWidth, cornerRadius, cornerRadius)
        doc.roundedRect(headerCol3 - 0.6, yCheckbox, checkBoxWidth, checkBoxWidth, cornerRadius, cornerRadius)
        doc.roundedRect(headerCol4 - 0.6, yCheckbox, checkBoxWidth, checkBoxWidth, cornerRadius, cornerRadius)

        yPos += lineHeightContent - 0.15

        const startBoxYPos = yPos
        for (let col = 0; col < 4; col++) {
          yPos = startBoxYPos + 0.05
          doc.setFont("THSarabun", "normal")
          doc.setFontSize(contentHeaderSize)
          const colStart = startBoxX + (boxWidth * col) + 0.1
          const lineStart = colStart + 0.6
          const lineEnd = colStart + boxWidth - 0.2
          doc.text(col === 0 ? "เวลาออก: " : "เวลาถึง: ", colStart, yPos)
          doc.line(lineStart, yPos + lineOffsetY, lineEnd, yPos + lineOffsetY)
          yPos += lineHeightContent
          doc.text("เลขไมล์หัว: ", colStart, yPos)
          doc.line(lineStart, yPos + lineOffsetY, lineEnd, yPos + lineOffsetY)
          yPos += lineHeightContent
          // doc.text("เลขไมล์หาง: ", colStart, yPos)
          // doc.line(lineStart, yPos + lineOffsetY, lineEnd, yPos + lineOffsetY)
          // yPos += lineHeightContent
          doc.text("%แบตเตอรี: ", colStart, yPos)
          doc.line(lineStart, yPos + lineOffsetY, lineEnd, yPos + lineOffsetY)
          if (col !== 0) {
            const endCol = startBoxX + (boxWidth * col)
            doc.line(endCol, startBoxY, endCol, startBoxY + boxHeight)
          }
        }
        yPos += 0.65
        // } else {
        //   doc.lines([[7.6, 0], [0, lineHeightContent * 3], [-7.6, 0], [0, lineHeightContent * 3 * -1]], startX - 0.1, yPos - 0.2, [1, 1], 'S', true)
        //   doc.setFont("THSarabun", "bold")
        //   doc.setFontSize(contentHeaderSize)
        //   doc.text("ข้อมูลใบเสร็จ", colHeader1, yPos)
        //   yPos += lineHeightContent - 0.15
        //   doc.setFont("THSarabun", "bold")
        //   doc.setFontSize(contentHeaderSize)
        //   doc.text("TAX ID : ", colHeader1, yPos)
        //   doc.text(jobOrderData.DXRmk || "", colText3 + 0.1, yPos + 0.05)
        //   doc.setFont("THSarabun", "normal")
        //   doc.setFontSize(contentSize)
        //   doc.text(`${shipmentData.Job.RcptTaxId} ${branchNoText(shipmentData.Job.RcptNm, shipmentData.Job.RcptBrchNo)}`, colText1, yPos)
        //   yPos += lineHeightContent - 0.15
        //   doc.setFont("THSarabun", "bold")
        //   doc.setFontSize(contentHeaderSize)
        //   doc.text("ชื่อใบเสร็จ : ", colHeader1, yPos)
        //   doc.setFont("THSarabun", "normal")
        //   doc.setFontSize(contentSize)
        //   doc.text(shipmentData.Job.RcptNm, colText1, yPos)
        //   yPos += lineHeightContent - 0.15
        //   doc.setFont("THSarabun", "bold")
        //   doc.setFontSize(contentHeaderSize)
        //   doc.text("ที่อยู่: ", colHeader1, yPos)
        //   doc.setFont("THSarabun", "normal")
        //   doc.setFontSize(contentSize - 5)
        //   const addrs = doc.splitTextToSize(shipmentData.Job.RcptAddr, 7)
        //   doc.text(addrs.join("\n"), colText1 - 0.3, yPos)
        //   yPos += lineHeightContent * 2 + lineHeightContent / 4
        // }
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        const signCol = allBoxWidth / 3
        const signColPadding = 1
        const startSignCol = startX + 0.3
        yPos -= 0.1
        for (let i = 0; i < 3; i++) {
          doc.line(startSignCol + (signCol * i), yPos, startSignCol + (signCol * i) + signCol - signColPadding, yPos)
        }
        const texts = ["ผู้รับสินค้า (ตัวแทน)", "ผู้จ่ายงาน", "พนักงานขับรถ"]
        yPos += 0.2
        for (let i = 0; i < 3; i++) {
          doc.text(texts[i], startSignCol + (signCol * i) + (signCol - signColPadding) / 2, yPos, { align: "center" })
        }
        if (jobOrderData.DrvId) {
          const drvObj = msData.driverObj[jobOrderData.DrvId]
          const drvFullNm = `${drvObj.FName} ${drvObj.LName}`
          doc.text(`(${drvFullNm})`, startSignCol + (signCol * 2) + (signCol - signColPadding) / 2, yPos + 0.2, { align: "center" })
        }
        if (fName) {
          doc.setFontSize(contentHeaderSize + 3)
          doc.text(`${fName}`, startSignCol + (signCol * 1) + (signCol - signColPadding) / 2, yPos - 0.25, { align: "center" })
        }
      }
      j++
      i++
    } while (j < jobOrdIds.length)
  }




  doc.output('dataurlnewwindow');
  // window.open(doc.autoPrint() , '_blank')
  // doc.autoPrint({variant: 'non-conform'});
  // window.open(doc.output('bloburl'), '_blank')
}