import { AddCircleRounded, ArticleRounded, EditRounded } from "@mui/icons-material"
import { Button, IconButton, Tooltip } from "@mui/material"
import { jobOrderColNm } from "constants/jobOrderConstant"
import dayjs from "dayjs"
import { pettyCashColNm } from "pages/Menu1/PettyCash/constant"
import { numberFormat } from "utils"

export const driverTripPaymentColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: 'ShowDetail',
    headerName: '',
    width: 60,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: 'AddData',
    headerName: '',
    width: 50,
    align: 'center',
    renderCell: (params) => {
      return (
        <Tooltip title="เพิ่มค่าเที่ยว" placement="top" followCursor>
          <IconButton size="small" sx={{ p: 0 }}>
            <AddCircleRounded color="primary" />
          </IconButton>
        </Tooltip>
      )
    }
  },
  {
    field: "CusSNm",
    headerName: jobOrderColNm.CusSNm,
    width: 70,
  },
  {
    field: "JobSNm",
    headerName: jobOrderColNm.JobSNm,
    width: 90,
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 70,
  },
  {
    field: "AptTm",
    headerName: "วันที่นัด",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "FinDte",
    headerName: "วันที่ลงจบ",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({row: {TakePlc, Loc, RtnPlc}}) => `${TakePlc}-${Loc}-${RtnPlc}`,
    width: 250,
  },
  // {
  //   field: "ContSizeFull",
  //   headerName: jobOrderColNm.ContSizeFull,
  //   width: 90,
  // },
  // {
  //   field: "Sts1NNm",
  //   headerName: "พนักงานรับ",
  //   width: 90,
  // },
  {
    field: "Sts3NNm",
    headerName: "พนักงานส่ง",
    width: 90,
  },
  {
    field: "Sts4NNm",
    headerName: "พนักงานพ้ก",
    width: 90,
  },
  // {
  //   field: "Sts5NNm",
  //   headerName: "พนักงานคืน",
  //   width: 90,
  // },
  {
    field: "AccNm",
    headerName: "ผุ้รับเงิน",
    valueGetter: ({row: {AccNm, DrvNm}}) => AccNm || DrvNm,
    width: 150,
  },
  
]

export const driverTripPaymentEndColumns = [
  {
    field: "OthAmnt",
    headerName: "ค่าอื่นๆ",
    width: 80,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "OthDscp",
    headerName: "รายละเอียดค่าอื่นๆ",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "Ref3",
    headerName: "เลขที่อ้างอิง",
    width: 190,
    editable: true,
  },
  {
    field: "SalDte",
    headerName: "คิดเงินดือน",
    width: 100,
    // valueFormatter: ({ value }) => value ? dayjs(value).format("MM/YYYY") : '',
    renderCell: ({ value }) => (
      <Button size="small" variant={value ? "outlined" : "contained"}>
        {value ? dayjs(value).format("MM/YYYY") : "กำหนดเดือน"}
      </Button>
    )
  },
]


export const otherDriverTripColumns = [

  {
    field: 'ShowDetail',
    headerName: '',
    width: 50,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" sx={{ p: 0 }}>
          <EditRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "UsrAccNm",
    headerName: pettyCashColNm.UsrAccId,
    minWidth: 120,
    flex: 1
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    minWidth: 120,
    flex: 1
  },
  {
    field: `Amnt`,
    headerName: pettyCashColNm.Amnt,
    width: 100,
    align: 'right',
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value === null || value === undefined ? '-' : numberFormat(value)
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    minWidth: 120,
    flex: 1
  },
]