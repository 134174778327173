import { MenuItem, TextField, Typography, Button } from "@mui/material";
import { alertSuccess } from "components/Alert";
import { BoxFC, BoxFR } from "components/BoxCustom";
import SaveButton from "components/buttons/SaveButton";
import SwitchFormControl from "components/SwitchFormControl";
import { coreApiNm } from "constants/serverConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext } from "react";
import { loadMSData } from "utils";
import { containerStatusColNm, textFieldProp } from "./constant";

const buttonMenuItemProp = {
  variant: "contained",
  sx: { height: 22 }
}
const ContainerStatusContent = ({ contStses, setContStses }) => {

  console.log("contStses::", contStses)
  const { ax, setMSData } = useContext(GlobalStateContext)

  const contStsProp = useCallback((name, value) => ({
    ...textFieldProp,
    name: name,
    value: value[name],
    label: containerStatusColNm[name],
    onChange: (e) => setContStses(o => {
      value[name] = e.target.value;
      return [...o]
    }),
  }), [setContStses])

  const handleSave = useCallback((e) => {
    ax.post(coreApiNm.updateContainerStatus, { argsArr: contStses }).then(value => {
      if (value.data) {
        loadMSData(ax, setMSData)
        alertSuccess("บันทึกข้อมูลเรียบร้อยแล้ว")
      }
    })
  }, [ax, contStses, setMSData])
  return (
    <BoxFC>
      <Typography variant="h6">สถานะตู้</Typography>

      <BoxFC pl={2} >
        {contStses.map(contSts => (
          <BoxFR key={contSts.ContStsId}>
            <TextField {...contStsProp("Name", contSts)} />
            <TextField {...contStsProp("NameEn", contSts)} />
            <TextField {...contStsProp("BgClr", contSts)} select >
              <MenuItem value="primary"> <Button {...buttonMenuItemProp} color="primary"> </Button></MenuItem>
              <MenuItem value="secondary"><Button {...buttonMenuItemProp} color="secondary"> </Button></MenuItem>
              <MenuItem value="success"><Button {...buttonMenuItemProp} color="success"> </Button></MenuItem>
              <MenuItem value="error"><Button {...buttonMenuItemProp} color="error"> </Button></MenuItem>
              <MenuItem value="warning"><Button {...buttonMenuItemProp} color="warning"> </Button></MenuItem>
              <MenuItem value="info"><Button {...buttonMenuItemProp} color="info"> </Button></MenuItem>
            </TextField>
            {/* 
            *
            * do not show Prty to edit coz quite difficult to handle when chnage 
            * coz all value must not duplicate
            * 
            <TextField {...contStsProp("Prty", contSts)} select>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </TextField> */}
            <SwitchFormControl checked={contSts.IsHide === 0 ? true : false}
              label={contSts.IsHide === 0 ? "แสดง" : "ซ่อน"}
              onChange={e => setContStses(o => {
                console.log("e.target.checked::", e.target.checked)
                contSts.IsHide = !e.target.checked ? 1 : 0
                return [...o]
              })} />
          </BoxFR>
        ))}

      </BoxFC>
      <SaveButton sx={{ width: 150 }} onClick={handleSave} />
    </BoxFC>
  )
}

export default React.memo(ContainerStatusContent)