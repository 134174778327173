import { TextField, Typography } from "@mui/material";
import { BoxFC } from "components/BoxCustom";
import React, { useCallback } from "react";
import { textFieldProp } from "./constant";
import SaveDataButton from "./SaveDataButton";

const SendEmailContent = ({sendEmailData, setSendEmailData}) =>{

  const sendEmailDataProps = useCallback((name) => ({
    ...textFieldProp,
    name: name,
    value: sendEmailData[name],
    onChange: (e) => setSendEmailData(o => ({ ...o, [name]: e.target.value }))
  }), [sendEmailData, setSendEmailData])
  return (
    <BoxFC>
      <Typography variant="h6">ข้อมูลการส่งอีเมล</Typography>
      <BoxFC pl={2} width={500}>
        <TextField {...sendEmailDataProps("Email")} label="อีเมล" />
        <TextField {...sendEmailDataProps("Pass")} label="พาสเวิร์ด" />
        {/* <TextField {...sendEmailDataProps("Cc")} label="Cc" />
        <TextField {...sendEmailDataProps("Bcc")} label="Bcc" />
        <TextField {...sendEmailDataProps("ReplyTo")} label="Reply To" /> */}
        <TextField {...sendEmailDataProps("Ending")} label="คำลงท้ายอีเมล" multiline rows={3} />
        <TextField {...sendEmailDataProps("InvPreText")} label="ข้อความ Invoice" />
      </BoxFC>
      <SaveDataButton sx={{width: 150}} name="SendEmailData" data={sendEmailData} />
    </BoxFC>
  )
}

export default React.memo(SendEmailContent)