import { TextField, Typography } from "@mui/material";
import { BoxFC } from "components/BoxCustom";
import React, { useCallback } from "react";
import { textFieldProp } from "./constant";
import SaveDataButton from "./SaveDataButton";

const PreTextContent = ({ preText, setPreText }) => {

  const preTextProps = useCallback((name) => ({
    ...textFieldProp,
    name: name,
    defaultValue: (preText[name] && preText[name].join(", ")) || "",
    onBlur: (e) => setPreText(o => ({ ...o, [name]: e.target.value ? e.target.value.split(",").map(item => item.trim()) : [] }))
  }), [preText, setPreText])
  return (
    <BoxFC height="100%" >
      <Typography variant="h6">ตัวเลือกข้อความ</Typography>
      <BoxFC pl={2} width={500} height="100%" sx={{overflowY:"scroll"}}>
        <TextField {...preTextProps("BookingReject")} label="สาเหตุปฏิเสธการจอง" multiline rows={3} />
        <TextField {...preTextProps("AddJobOrderChild")} label="เพิ่มใบงานย่อย" multiline rows={3} />
        <TextField {...preTextProps("CustomerFiles")} label="อัพโหลดไฟล์ลูกค้า" multiline rows={3} />
        <TextField {...preTextProps("JobFiles")} label="อัพโหลดไฟล์งาน" multiline rows={3} />
        <TextField {...preTextProps("TruckFiles")} label="อัพโหลดไฟล์รถ" multiline rows={3} />
        <TextField {...preTextProps("TrailerFiles")} label="อัพโหลดไฟล์หางพ่วง" multiline rows={3} />
        <TextField {...preTextProps("ShipmentFiles")} label="อัพโหลดไฟล์ใบแจ้งลาก" multiline rows={3} />
        <TextField {...preTextProps("InvoiceFiles")} label="อัพโหลดไฟล์ Invoice" multiline rows={3} />
        <TextField {...preTextProps("ReceiveVoucherFiles")} label="อัพโหลดไฟล์ การรับชำระเงิน" multiline rows={3} />
        <TextField {...preTextProps("FuelFiles")} label="อัพโหลดรูปการเติมเชื้อพลิง" multiline rows={3} />
        <TextField {...preTextProps("DailyExpense")} label="รายได้ บัญชีเงินสด" multiline rows={3} />
        <TextField {...preTextProps("DriverIncomeExtras")} label="ค่าเที่ยวพิเศษ" multiline rows={3} />
        <TextField {...preTextProps("Times")} label="ตัวเลือกเวลา" multiline rows={3} />
      </BoxFC>
      <SaveDataButton sx={{ width: 150 }} name="PreText" data={preText} />
    </BoxFC>
  )
}

export default React.memo(PreTextContent)